import React from "react";
import "../../Styles/Profile.css";
import { useLocation, useNavigate } from "react-router";
// import Navbar from "../Core/Navbar";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Header from "../Components/Header";
import { addUser, updateUser } from "../../Services/Operations/ProductAPI";

function AddAddress() {
  const location = useLocation();
  const { address } = location.state || {};
  const { tokenDetails } = useSelector((state) => state.user);
  const token = tokenDetails;
  console.log("address : ",address)
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  React.useEffect(() => {
    if (address) {
      setValue("name", address.name);
      setValue("mobile_no", address.mobile_no);
      setValue("address", address.address);
      setValue("city", address.city);
      setValue("state", address.state);
      setValue("country", address.country);
      setValue("pincode", address.pincode);
    }
  }, [address, setValue]);

  const onSubmit = async (data) => {
    if (address) {
      console.log("Updating address...");
      const updatedData = {
        ...address,
        ...data,
        addressId: address.id,
      };
      delete updatedData.id;
        try {
          const result = await updateUser(updatedData,token)
          console.log("update response : ",result);
          if (result.success === true) {
            toast.success("Address updated successfully!");
            navigate("/addresses");
          }
        } catch (error) {
          console.error("Error updating address:", error);
          toast.error("Failed to update address. Please try again.");
        }
    } else {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("mobile_no", data.mobile_no);
      formData.append("address", data.address);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("country", data.country);
      formData.append("pincode", data.pincode);

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
        const result = await addUser(data, token);
        console.log(result);
        if (result.success === true) {
          toast.success("Address is added");
          navigate("/addresses");
        }
    }
  };

  return (
    <div className="add-address-container body-container default is-ctl-home-page is-ctl-landingpage is-ctl-navigation is-act-home">
     <Header />
      <div style={{ maxWidth: "970px", width: "100%", margin: "auto",padding:"20px" }}>
        <h3 style={{ textAlign: "center", marginTop: "31px",fontSize:"28px" }}>
          Add New Address
        </h3>
        <form onSubmit={handleSubmit(onSubmit)} style={{ gap: "23px" }}>
          <input
            style={{ outline: "none",border: "2px solid #e5e5e5" }}
            type="text"
            placeholder="Full name"
            {...register("name", { required: "Full name is required" })}
          />
          {errors.name && <p className="error">{errors.name.message}</p>}
          <input
            style={{ outline: "none",border: "2px solid #e5e5e5" }}
            type="text"
            placeholder="Phone Number"
            {...register("mobile_no", { required: "Phone number is required" })}
          />
          {errors.mobile_no && (
            <p className="error">{errors.mobile_no.message}</p>
          )}
          <input
            style={{ outline: "none",border: "2px solid #e5e5e5" }}
            type="text"
            placeholder="Address"
            {...register("address", { required: "Address is required" })}
          />
          {errors.address && <p className="error">{errors.address.message}</p>}
          <input
            style={{ outline: "none",border: "2px solid #e5e5e5" }}
            type="text"
            placeholder="City"
            {...register("city", { required: "City is required" })}
          />
          {errors.city && <p className="error">{errors.city.message}</p>}
          <input
            style={{ outline: "none",border: "2px solid #e5e5e5" }}
            type="text"
            placeholder="State/Province/Region"
            {...register("state", {
              required: "State/Province/Region is required",
            })}
          />
          {errors.state && <p className="error">{errors.state.message}</p>}
          <input
            style={{ outline: "none",border: "2px solid #e5e5e5" }}
            type="text"
            placeholder="Country"
            {...register("country", { required: "Country is required" })}
          />
          {errors.country && <p className="error">{errors.country.message}</p>}
          <input
            style={{ outline: "none",border: "2px solid #e5e5e5" }}
            type="text"
            placeholder="Zip Code (Postal Code)"
            {...register("pincode", { required: "Zip code is required" })}
          />
          {errors.pincode && <p className="error">{errors.pincode.message}</p>}
          <button type="submit">SAVE ADDRESS</button>
        </form>
      </div>
    </div>
  );
}

export default AddAddress;
