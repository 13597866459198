import React, { useEffect, useState } from "react";
import Header from "./Components/Header";
import Banner from "./Components/Banner";
import BannerBottom from "./Components/BannerBottom";
import Configurator from "./Components/Configurator";
import TakeClub from "./Components/TakeClub";
import Discounts from "./Components/Discounts";
import Discover from "./Components/Discover";
import Footer from "./Components/Footer";
import Designed from "./Components/Designed";
import "./Css/fonts.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { config_Api } from "../Services/Operations/ProductAPI";
import { useDispatch } from "react-redux";

function Home() {
  const [showPopup, setShowPopup] = useState(false);
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [configUrl,setConfigUrl]=useState("")

  // const onSubmit = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://sports.gainenterprises.in/api/v1/webconfig"
  //     );
  //     console.log("res", response.data);
      
  //     if (response.data.status === "yes") {
  //       setShowPopup(true); 
  //     }
  //   } catch (error) {
  //     alert(error);
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   handleSubmit(onSubmit)(); // Call onSubmit when component mounts
  // }, []);

  // const closePopup = () => {
  //   setShowPopup(false);
  // };

  useEffect(() => {
    let isMounted = true; 
  
    const fetchConfig = async () => {
      try {
        const response = await config_Api();
        console.log("Config in Home:", response);
        if (isMounted && response.success === true) {
          setConfigUrl(response.data.assetUrl);
          console.log("ajgaca");
        }
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    };
  
    fetchConfig();
  
    return () => {
      isMounted = false; 
    };
  }, []);
  
  return (
    <div className="body-container default is-ctl-home-page is-ctl-landingpage is-ctl-navigation is-act-home">
      <Header />
      <main className="content-main">
        <div data-flashbags-container="true" className="flashbags"></div>
        <div className="container-main">
          <div className="cms-page set-banner-link-new-tab">
            <div className="cms-sections">
              <Banner />
              <BannerBottom />
              <Configurator />
              <TakeClub />
              <Designed />

              <Discounts />
            </div>
          </div>
        </div>
      </main>

      <Discover />

      <Footer />

      <div className="js-pseudo-modal-template">
        <div className="modal fade" tabindex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header only-close">
                <div className="modal-title js-pseudo-modal-template-title-element h5"></div>
                <button
                  type="button"
                  className="btn-close close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body js-pseudo-modal-template-content-element"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="overlayCategoryModal0e9ee1f578ab4a3890b94947250a6521"
        className="d-none spized-advanced-category-overlay-modal bgweb2 f_spized-overlay-modal is_active"
        data-action="/en/widgets/cms/0e9ee1f578ab4a3890b94947250a6521"
        data-overlay-cms-page="true"
      >
        <div className="overlay-cms-top">
          <a
            href="javascript:void(0);"
            aria-label="close-modal"
            className="close-overlay-modal btn button-icon btn-highlighted js--close-overlay"
          >
            <i className="icon-close"></i>
          </a>
        </div>
        <div className="overlay-cms-inner js--overlay-cms-inner"></div>
      </div>
      <div data-mediameets-facebook-pixel-options='{"config":{"pixelIds":"247919152929715","privacyMode":"active","autoConfig":true,"advancedMatching":true,"includeShippingCosts":true,"useConversionApi":true,"disablePushState":true,"conversionApiDeduplication":"eventid"},"shop":{"controller":"navigation","action":"home","currency":"EUR"},"routes":{"customer":"\/en\/mm-fp\/customer","cart":"\/en\/mm-fp\/cart","order":"\/en\/mm-fp\/order"},"conversionApi":{"endpoint":"\/en\/mmfbpcapi"}}'></div>
      <script
        type="text/javascript"
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyA3TCkogy3gzKhdcm1RkR234Je3IoR7qeE&amp;libraries=places&amp;language=en-GB"
      ></script>
      <script>
        window.router['frontend.configurator.page'] = '/en/configurator';
        window.router['frontend.configurator.save'] = '/en/configurator/save';
        window.router['frontend.configurator.get-product-api-data'] =
        '/en/configurator/get-product-api-data';
        window.router['frontend.checkout.store-configurations'] =
        '/en/checkout/store-configurations';
        window.router['frontend.account.drafts.delete'] =
        '/en/account/drafts/delete';
        window.router['frontend.configurator.add-to-session'] =
        '/en/configurator/add-to-session';
        window.router['frontend.configurator.restore-in-session'] =
        '/en/configurator/restore-in-session';
        window.router['frontend.configurator.track-unique'] =
        '/en/configurator/track-unique';
        window.router['frontend.savings-calculator.fb-track'] =
        '/en/savings-calculator/fb-track';
        window.router['frontend.configurator.save-if-draft'] =
        '/en/configurator/save-if-draft';
        window.router['frontend.configurator.update-in-session'] =
        '/en/configurator/update-in-session';
        window.router['frontend.configurator.remove-from-session'] =
        '/en/configurator/remove-from-session';
        window.router['frontend.configurator.mail-desktop-link'] =
        '/en/configurator/mail-desktop-link';
        window.router['frontend.account.drafts.page'] = '/en/account/drafts';
        window.router['frontend.configurator.share'] = '/en/configurator/share';
        window.router['frontend.checkout.pre_cart.page'] =
        '/en/checkout/pre-cart'; window.router['frontend.checkout.cart.page'] =
        '/en/checkout/cart'; window.router['frontend.checkout.addressbook'] =
        '/en/widgets/checkout/address-book'; window.router['frontend.poll.save']
        = '/en/poll/save';
        window.router['frontend.account.teamshop.api.create-update-configuration']
        = '/en/account/teamshop/api/create-update-configuration';
        window.router['frontend.checkout.address.refresh'] =
        '/en/checkout/address/refresh';
        window.router['frontend.checkout.confirm'] = '/en/checkout/confirm';
        window.router['frontend.checkout.confirm.validate'] =
        '/en/checkout/confirm/validate';
        window.router['frontend.checkout.address.set-default-address'] =
        '/en/checkout/address/set-default-address';
        window.router['frontend.checkout.cross-selling-product-count'] =
        '/en/cross-selling/product-count';
        window.router['frontend.checkout.cross-selling.page'] =
        '/en/cross-selling/products';
        window.router['frontend.product.quantity-save-to-session'] =
        '/en/product/quantity-save-to-session';
        window.router['frontend.product.get-quantity-from-session'] =
        '/en/product/get-quantity-from-session';
        window.router['frontend.hreflang.get'] = '/en/hreflang/get';
        window.router['frontend.breadcrumb.get'] = '/en/breadcrumb/get';
        window.router['frontend.checkout.confirm.get-delivery-country-list'] =
        '/en/checkout/confirm/get-delivery-country-list';
        window.router['frontend.checkout.confirm.reload-cart-data'] =
        '/en/checkout/confirm/reload-cart-data';
        window.router['frontend.checkout.confirm.reload-cart-data'] =
        '/en/checkout/confirm/reload-cart-data';
        window.router['frontend.configurator.check-if-already-collapsed'] =
        '/en/configurator/check-if-already-collapsed';
        window.router['frontend.configurator.save-already-collapsed'] =
        '/en/configurator/save-already-collapsed';
      </script>

      {/* {showPopup && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              textAlign: "center",
              maxWidth: "300px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p>API responded with status "yes".</p>
            <button
              onClick={closePopup}
              style={{
                marginTop: "10px",
                padding: "8px 16px",
                cursor: "pointer",
                backgroundColor: "#007BFF",
                color: "white",
                border: "none",
                borderRadius: "4px",
              }}
            >
              Close
            </button>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default Home;
