import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Design from "../Products/Design";
import MoreInteresting from "../Products/MoreInteresting";
import Footer from "../Components/Footer";
import MenFilter from "./MenFilter";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { productDetails } from "../../Services/Operations/ProductAPI";

function ProductListing() {
  const navigate = useNavigate();
  const [particularProduct, setParticularProduct] = useState({});
  const location = useLocation();
  // const productData1 = location.state?.productData;
  // console.log("product : ", productData1);
  const { productData, menu, categoryName } = location.state || {};
  const productData1 = productData;

  console.log("Product Data:", productData1);
  console.log("Category Name:", categoryName);

  const clickProductDetailsHandler = async (name) => {
    try {
      console.log("productSlug:", name);
      const formData = new FormData();
      formData.append("slug", name);

      const response = await productDetails(formData);
      console.log("API response for product details:", response);

      if (response.success === true) {
        setParticularProduct(response.data);
        console.log("Navigating to productListing with data:", response.data);
        navigate("/detailsPage", {
          state: { particularProduct: response.data },
        });
      }
    } catch (error) {
      console.error("Error during product API call:", error);
    }
  };

  return (
    <div>
      <body className="body-container default is-ctl-spized-listing-with-seo-box is-ctl-product_list is-ctl- is-act-">
        <Header />
        {/* <main className="content-main">
          <div data-flashbags-container="true" className="flashbags"></div>
          <div className="container-main">
            <div className="cms-page">
              <div className="cms-sections">
                <div className="cms-section block-container-2 listing-content-emotion pos-0 cms-section-sidebar">
                  <div className="cms-section-sidebar cms-section-sidebar-mobile-wrap boxed row">
                    <MenFilter menu={menu} categoryName={categoryName}/>
                    <div className="cms-section-sidebar-main-content col-lg-8 col-xl-9">
                      <div className="cms-block  pos-2 cms-block-product-listing">
                        <div className="cms-block-container">
                          <div className="cms-block-container-row row cms-row">
                            <div className="col-12">
                              <div className="row cms-listing-row js-listing-wrapper">
                                {productData1.map((product) => (
                                  <div
                                    key={product.id}
                                    className="cms-listing-col col-sm-6 col-lg-6 col-xl-4"
                                    style={{ marginTop: 20, marginBottom: 40 }}
                                  >
                                    <div className="card product-box box-standard">
                                      <div className="card-body">
                                        <div className="product-image-wrapper">
                                          <a
                                            href=" "
                                            title={product.title}
                                            className="product-image-link is-standard has-hover-image"
                                            onClick={(event) => {
                                              event.preventDefault();
                                              clickProductDetailsHandler(
                                                product.slug
                                              );
                                            }}
                                          >
                                            <img
                                              width="600"
                                              height="600"
                                              src={`https://sheildsports.com/main/public/${product.gallery[0].image}`}
                                              alt={product.name}
                                              className="f_coverImage cover-image product-image is-standard"
                                            />
                                            <img
                                              width="600"
                                              height="600"
                                              src={`https://sheildsports.com/main/public/${product.gallery[1].image}`}
                                              alt={product.name}
                                              className="f_hoverImage is-transparent hover-image product-image is-standard"
                                            />
                                          </a>

                                        </div>
                                        <div
                                          className="product-info"
                                          style={{
                                            fontFamily: "sans-serif",
                                          }}
                                        >
                                          <a
                                            href={product.link}
                                            className="product-name"
                                            title={product.name}
                                          >
                                            {product.name}
                                          </a>
                                          <div
                                            className="product-price-info"
                                            style={{
                                              fontFamily: "sans-serif",
                                            }}
                                          >
                                            <ul
                                              className="multiple-price-group"
                                              style={{ paddingLeft: 0 }}
                                            >
                                              <li className="product-price-wrapper">
                                                Unit price for{" "}
                                
                                                pcs.:{" "}
                                                <span className="product-price">
                                                  {product.price}
                                                </span>
                                              </li>
                                              <div className="product--unit-price">
                                                <div className="price--content content--default">
                                                  Unit price for{" "}
                                                  
                                                  pcs.:{" "}
                                                  <span className="f_spizedArticleUnitPrice article-unit-price">
                                                    {product.mrp}
                                                  </span>
                                                </div>
                                              </div>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main> */}
        <main className="content-main">
          <div className="flashbags"></div>
          <div className="container-main">
            <div className="page-layout">
              <div className="page-layout-wrapper">
                <aside className="sidebar">
                  <MenFilter menu={menu} categoryName={categoryName} />
                </aside>
                <section className="main-content">
                  <div className="product-listing">
                    <div className="product-grid">
                      {productData1.map((product) => (
                        <div key={product.id} className="product-card">
                          <div className="product-box">
                            <div className="product-image-wrapper">
                              <a
                                href=" "
                                className="product-link1"
                                onClick={(event) => {
                                  event.preventDefault();
                                  clickProductDetailsHandler(product.slug);
                                }}
                                style={{height:"100%"}}
                              >
                                <img
                                  src={`https://sheildsports.com/main/public/${product.gallery[0].image}`}
                                  alt={product.name}
                                  className="product-image"
                                  style={{width:"100%",maxHeight:"100%",height:"100%"}}
                                />
                                <img
                                  src={`https://sheildsports.com/main/public/${product.gallery[1].image}`}
                                  alt={product.name}
                                  className="hover-image"
                                />
                              </a>
                            </div>
                            <div className="product-info">
                              <a href={product.link} className="product-name">
                                {product.name}
                              </a>
                              <div className="product-price">
                                Unit price: <span>{product.price}</span>
                              </div>
                              <div className="product-mrp">
                                MRP: <span className="mrp">{product.mrp}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
        <Design />
        <MoreInteresting />
        <Footer />
      </body>
    </div>
  );
}

export default ProductListing;
