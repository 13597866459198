import React, { useEffect, useState } from "react";
import "../../Styles/Address.css";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { deleteUser, getUser } from "../../Services/Operations/ProductAPI";
import { toast } from "react-toastify";
import Header from "../Components/Header";

export const Address = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const { tokenDetails } = useSelector((state) => state.user);
  const token = tokenDetails;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUser(token);
        console.log("address :", response);
        if (response.success) {
          setAddress(response.data);
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        toast.error("An error occurred while fetching addresses.");
      }
    };

    fetchData();
  }, [refreshKey, token]);

  const handleDeleteAddress = async (id) => {
    const formData = new FormData();
    formData.append("addressId", id);

    console.log("Deleting address with ID:", id);
    const result = await deleteUser(formData, token);
    if (result.success) {
      toast.success("Address deleted successfully");
      setRefreshKey((prev) => prev + 1);
    }
  };

  const handleEditAddress = (id) => {
    console.log("Id : ", id);
    const addressToEdit = address.find((item) => item.id === id);
    console.log("addressToEdit : ", addressToEdit);
    navigate("/add-addresses", { state: { address: addressToEdit } });
  };

  return (
    <div className="add-address-container body-container default is-ctl-home-page is-ctl-landingpage is-ctl-navigation is-act-home">
      <Header/>
      <div className="container1">
        <h2 className="title1">Select Delivery Address</h2>
        <button className="add-btn1" onClick={() => navigate("/add-addresses")}>
          + Add New Address
        </button>
        <div className="address-card1">
          {address.map((details) => (
            <div key={details.id}>
              <h3 className="name1">{details.name}</h3>
              <p className="address1">
                {details.address}, {details.city},{details.state},
                {details.country}, {details.pincode}
              </p>
              <p className="phone1">{details.mobile_no}</p>
              <div className="actions1">
                <FaEdit
                  className="icon edit-icon1"
                  title="Edit"
                  onClick={() => handleEditAddress(details.id)}
                />
                <FaTrash
                  className="icon delete-icon1"
                  title="Delete"
                  onClick={() => handleDeleteAddress(details.id)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
