import React, { useState } from "react";
import Header from "../Components/Header";
import Discover from "../Components/Discover";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import { get_login, get_register } from "../../Services/Operations/ProductAPI";
import { setLoginData, setSignupData } from "../../Redux/user_information";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function SignUp() {
  const [isLoginVisible, setIsLoginVisible] = useState(true); 
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleForms = () => {
    setIsLoginVisible((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim() || !password.trim()) {
      alert("Enter all fields");
    } else {
      try {
        // const response = await axios.post(`https://sports.gainenterprises.in/api/v1/customer/login`, { email, password });

        // console.log("Signin successful:", response.data.data);
        // if (response.data.success === true) {
        //   localStorage.setItem(
        //     "access_token",
        //     JSON.stringify(response.data.data.access_token)
        //   );

        //   setEmail("");
        //   setPassword("");
        //   navigate("/");
        // }
        const result = await get_login({ email, password });
        console.log("login data : ",result);
        if (result.success === true) {
          dispatch(setLoginData(result.data.access_token));
          setName("")
          setEmail("");
          setPassword("");
          toast.success(result.message)
          navigate("/");
        }else{
          toast.error(result.message)
        }
      } catch (error) {
        console.error("Signin error:", error);
      }
    }
  };
  const handleRegisterSubmit = async (e) => {
    e.preventDefault();

    if (!name.trim() || !email.trim() || !password.trim()) {
      alert("Enter all fields");
    } else {
      try {
        // const response = await axios.post(`https://sports.gainenterprises.in/api/v1/customer/register`, {name, email, password });

        // console.log("Signup successful:", response.data);
        // if (response.data.success === true) {
        //   localStorage.setItem(
        //     "access_token",
        //     JSON.stringify(response.data.data.access_token)
        //   );
        //   setName("")
        //   setEmail("");
        //   setPassword("");
        //   navigate("/");
        // }else{
        //   alert(response.data.message)
        // }
        const result = await get_register({name, email, password });
         if (result.success === true) {
          dispatch(setLoginData(result.data.access_token));
          setName("")
          setEmail("");
          setPassword("");
          navigate("/");
        }else{
          alert(result.data.message)
        }
        console.log("register data : ",result);
      } catch (error) {
        console.error("Signup error:", error);
      }
    }
  };

  return (
    <div className="body-container default is-ctl-auth is-act-loginpage">
      <div>
        <Header />

        <main className="content-main">
          <div data-flashbags-container="true" className="flashbags"></div>
          <div className="container">
            <div className="container-main">
              <div className="account-register">
                <div className="row">
                  <div className="col-12">
                    <div className="login-register-content-box" id="loginForm">
                      <div className="card login-card bgweb">
                        <div className="card-body">
                          <div className="card-title">
                            {isLoginVisible
                              ? "I'm already a customer."
                              : "I'm a new customer!"}
                          </div>
                          <p
                            className="login-form-description default"
                            style={{
                              fontFamily: "sans-serif",
                            }}
                          >
                            {isLoginVisible
                              ? "Log in with your email address and password."
                              : "Register here with your data."}
                          </p>

                          {/* Login Form */}
                          {isLoginVisible && (
                            <div id="collapseLogin" className="show">
                              <form
                                className="login-form"
                                action="https://www.spized.com/en/account/login"
                                method="post"
                                data-form-validation="true"
                              >
                                <input
                                  type="hidden"
                                  name="redirectTo"
                                  value="frontend.account.home.page"
                                />
                                <input
                                  type="hidden"
                                  name="redirectParameters"
                                  value="[]"
                                />
                                <div className="row g-2">
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-label"
                                      htmlFor="loginMail"
                                      style={{
                                        fontFamily: "sans-serif",
                                      }}
                                    >
                                      Your email address
                                    </label>
                                    <input
                                      className="form-control"
                                      id="loginMail"
                                      type="email"
                                      placeholder="Enter Email"
                                      required
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label
                                      className="form-label"
                                      htmlFor="loginPassword"
                                      style={{
                                        fontFamily: "sans-serif",
                                      }}
                                    >
                                      Your password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="loginPassword"
                                      placeholder="Password"
                                      required
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="login-submit">
                                  <button
                                    type="submit"
                                    className="btn-collapse button6 btn-outline"
                                    style={{
                                      backgroundColor: "black",
                                      color: "white",
                                      fontFamily: "sans-serif",
                                    }}
                                    onClick={handleSubmit}
                                  >
                                    Sign in
                                  </button>
                                </div>
                                <div className="login-password-recover">
                                  <a
                                    onClick={()=>navigate("/forgot-password")}
                                    style={{
                                      fontFamily: "sans-serif",
                                    }}
                                  >
                                    Forgot your password?
                                  </a>
                                </div>
                              </form>
                            </div>
                          )}

                          {/* Register Form */}
                          {!isLoginVisible && (
                            <div id="collapseRegister">
                              <form
                                action="https://www.spized.com/en/account/register"
                                className="register-form"
                                method="post"
                                data-form-validation="true"
                              >
                                <div className="register-personal small">
                                  <div className="row g-2">
                                    <div className="form-group col-sm-6">
                                      <label
                                        className="form-label"
                                        htmlFor="personalMail"
                                        style={{
                                          fontFamily: "sans-serif",
                                        }}
                                      >
                                        Your name*
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        autoComplete="section-personal given-name"
                                        id="personalFirstName"
                                        placeholder="Enter your name..."
                                        name="name"
                                        required="required"
                                        value={name}
                                      onChange={(e) =>
                                        setName(e.target.value)
                                      }
                                      />
                                    </div>
                                    <div className="form-group col-sm-6">
                                      <label
                                        className="form-label"
                                        htmlFor="personalMail"
                                        style={{
                                          fontFamily: "sans-serif",
                                        }}
                                      >
                                        Your email address*
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        autoComplete="section-personal email"
                                        id="personalMail"
                                        placeholder="Enter email address..."
                                        name="email"
                                        required="required"
                                        value={email}
                                      onChange={(e) =>
                                        setEmail(e.target.value)
                                      }
                                      />
                                    </div>
                                    <div className="form-group col-sm-6">
                                      <label
                                        className="form-label"
                                        htmlFor="personalPassword"
                                        style={{
                                          fontFamily: "sans-serif",
                                        }}
                                      >
                                        Password*
                                      </label>
                                      <input
                                        type="password"
                                        className="form-control"
                                        autoComplete="new-password"
                                        id="personalPassword"
                                        placeholder="Enter password..."
                                        name="password"
                                        minLength="8"
                                        required="required"
                                        value={password}
                                        onChange={(e) =>
                                          setPassword(e.target.value)
                                        }
                                      />
                                      <small
                                        className="form-text js-validation-message"
                                        style={{
                                          fontFamily: "sans-serif",
                                        }}
                                      >
                                        Your password must contain at least 8
                                        characters.
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="register-submit"
                                  style={{ marginBottom: "50px" }}
                                  onClick={handleRegisterSubmit}
                                >
                                  <button
                                    type="submit"
                                    className="btn-collapse button6 btn-outline"
                                    style={{
                                      backgroundColor: "black",
                                      color: "white",
                                      fontFamily: "sans-serif",
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          )}
                          {/* Toggle Button */}
                          <div className="card-title">
                            {isLoginVisible
                              ? "I'm a new customer."
                              : "I'm already a customer!"}
                          </div>
                          <p
                            className="login-form-description default"
                            style={{
                              fontFamily: "sans-serif",
                            }}
                          >
                            {isLoginVisible
                              ? "Register here with your data."
                              : "Log in with your email address and password."}
                          </p>
                          <button
                            onClick={toggleForms}
                            className="btn-collapse button6 btn-outline"
                            style={{
                              backgroundColor: "black",
                              color: "white",
                              fontFamily: "sans-serif",
                            }}
                          >
                            {isLoginVisible ? "Sign up" : "Sign in"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Additional Information */}
                  <div className="login-advantages col-12">
                    <div className="login-advantages-item">
                      {/* icon */}
                      <div className="login-advantages-info">
                        <div className="login-advantages-title">
                          All printing costs included
                        </div>
                        <div className="login-advantages-description">
                          No additional costs for logos, numbers or names
                        </div>
                      </div>
                    </div>
                    <div className="login-advantages-item">
                      {/* icon */}
                      <div className="login-advantages-info">
                        <div className="login-advantages-title">
                          Safe and fast delivery
                        </div>
                        <div className="login-advantages-description">
                          Speed is not only important in sport. We deliver as
                          fast as possible.
                        </div>
                      </div>
                    </div>
                    <div className="login-advantages-item">
                      {/* icon */}
                      <div className="login-advantages-info">
                        <div className="login-advantages-title">
                          EU, UK, Switzerland
                        </div>
                        <div className="login-advantages-description">
                          We ship within the EU, Switzerland and UK
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Discover />
        <Footer />
      </div>
    </div>
  );
}

export default SignUp;
