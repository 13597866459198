// import React, { useState } from "react";

// function MenFilter() {
//   const [quantity, setQuantity] = useState(10);

//   const changeCount = (action) => {
//     setQuantity((prevQuantity) => {
//       if (action === "increase") {
//         return prevQuantity + 1;
//       } else if (action === "decrease" && prevQuantity > 0) {
//         return prevQuantity - 1;
//       }
//       return prevQuantity;
//     });
//   };

//   const calculate = () => {
//     alert(`You have calculated a quantity of: ${quantity}`);
//   };
//   return (
//     <div className="cms-section-sidebar-sidebar-content col-lg-4 col-xl-3">
//       <div className="cms-block  pos-3 cms-block-sidebar-filter">
//         <div className="cms-block-container">
//           <div className="cms-block-container-row row cms-row has--sidebar">
//             <div
//               className="col-12"
//               data-cms-element-id="b3cdf007173c4e159586737b2b102f11"
//             >
//               <div className="cms-element-sidebar-filter">
//                 <div className="oppened-filters"></div>
//                 <div
//                   id="filter-panel-wrapper"
//                   className="filter-panel-wrapper"
//                   data-offcanvas-filter-content="true"
//                 >
//                   <div className="filter-panel-offcanvas-header">
//                     <div className="filter-panel-offcanvas-only">
//                       <h3 className="filter-panel-offcanvas-title">
//                         MenFilter
//                       </h3>
//                       <div className="filter-panel-offcanvas-close js-offcanvas-close">
//                         <span className="icon icon-x icon-md">
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             xlink="http://www.w3.org/1999/xlink"
//                             width="24"
//                             height="24"
//                             viewBox="0 0 24 24"
//                           >
//                             <defs>
//                               <path
//                                 d="m10.5858 12-7.293-7.2929c-.3904-.3905-.3904-1.0237 0-1.4142.3906-.3905 1.0238-.3905 1.4143 0L12 10.5858l7.2929-7.293c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L13.4142 12l7.293 7.2929c.3904.3905.3904 1.0237 0 1.4142-.3906.3905-1.0238.3905-1.4143 0L12 13.4142l-7.2929 7.293c-.3905.3904-1.0237.3904-1.4142 0-.3905-.3906-.3905-1.0238 0-1.4143L10.5858 12z"
//                                 id="icons-default-x"
//                               />
//                             </defs>
//                             <use
//                               href="#icons-default-x"
//                               fill="#758CA3"
//                               fill-rule="evenodd"
//                             />
//                           </svg>
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="filter-panel is--sidebar">
//                     <div className="filter-panel-active-container"></div>

//                     <div className="filter-panel-items-container">
//                       <div
//                         className="filter-multi-select filter-multi-select-properties filter-panel-item d-grid"
//                         data-filter-property-select="true"
//                         data-filter-property-select-options='{"name":"properties","propertyName":"Product category","snippets":{"disabledFilterText":"This filter does not display any further results in combination with the selected filters."}}'
//                       >
//                         <button
//                           className="filter-panel-item-toggle btn"
//                           aria-expanded="false"
//                           data-bs-toggle="collapse"
//                           data-bs-target="#filter-properties-1461025610"
//                         >
//                           Colors
//                           <span className="filter-multi-select-count"></span>
//                           <span className="icon icon-arrow-medium-down icon-xs icon-filter-panel-item-toggle">
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               xlink="http://www.w3.org/1999/xlink"
//                               width="16"
//                               height="16"
//                               viewBox="0 0 16 16"
//                             >
//                               <use
//                                 href="#icons-solid-arrow-medium-down"
//                                 fill="#758CA3"
//                                 fill-rule="evenodd"
//                               />
//                             </svg>
//                           </span>
//                         </button>
//                         <div
//                           className="filter-multi-select-dropdown filter-panel-item-dropdown collapse"
//                           id="filter-properties-1461025610"
//                         >
//                           <ul
//                             className="filter-multi-select-list"
//                             data-id="018ea94198d97fc78914e87756acdb9f"
//                             data-title-prefix
//                             data-title-postfix
//                             data-title-position="3"
//                             data-url-position="3"
//                             data-related-property-groups="{}"
//                             data-hide-from-title-on-related-property-groups='["018ea944b6d079d5831860265a2d4c4d"]'
//                             data-hide-category-from-title="0"
//                           >
//                             <li
//                               className="filter-multi-select-list-item filter-property-select-list-item"
//                               data-id="019078cea2277da1b1c30d93cc3302f2"
//                               data-url-name="fanscarfs"
//                               data-title-name="fanscarfs"
//                               data-use-as-prefix
//                               data-category-postfix
//                               data-hide-listing-title-postfix
//                               data-hide-category-title-postfix="1"
//                               data-category-prefix
//                               data-meta-title
//                               data-meta-description
//                             >
//                               <a
//                                 className="filter-multi-select-list-item-link filter-property-select-list-item-link"
//                                 href
//                               >
//                                 <div className="form-check">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input filter-multi-select-checkbox"
//                                     data-label="Fanscarfs"
//                                     value="019078cea2277da1b1c30d93cc3302f2"
//                                     id="019078cea2277da1b1c30d93cc3302f2"
//                                   />
//                                   <label
//                                     className="filter-multi-select-item-label form-check-label"
//                                     for="019078cea2277da1b1c30d93cc3302f2"
//                                   >
//                                     <span className="text-block">
//                                       Fanscarfs
//                                     </span>
//                                   </label>
//                                 </div>
//                               </a>
//                             </li>
//                             <li
//                               className="filter-multi-select-list-item filter-property-select-list-item"
//                               data-id="018ea94323167c63b9e7a83d7ed29c01"
//                               data-url-name="headgear"
//                               data-title-name="headgear"
//                               data-use-as-prefix
//                               data-category-postfix
//                               data-hide-listing-title-postfix
//                               data-hide-category-title-postfix="1"
//                               data-category-prefix
//                               data-meta-title
//                               data-meta-description
//                             >
//                               <a
//                                 className="filter-multi-select-list-item-link filter-property-select-list-item-link"
//                                 href
//                               >
//                                 <div className="form-check">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input filter-multi-select-checkbox"
//                                     data-label="Headgear"
//                                     value="018ea94323167c63b9e7a83d7ed29c01"
//                                     id="018ea94323167c63b9e7a83d7ed29c01"
//                                   />
//                                   <label
//                                     className="filter-multi-select-item-label form-check-label"
//                                     for="018ea94323167c63b9e7a83d7ed29c01"
//                                   >
//                                     <span className="text-block">Headgear</span>
//                                   </label>
//                                 </div>
//                               </a>
//                             </li>
//                             <li
//                               className="filter-multi-select-list-item filter-property-select-list-item"
//                               data-id="018ea942f72f7f7e9d3d9da3f336434b"
//                               data-url-name="jackets"
//                               data-title-name="jackets"
//                               data-use-as-prefix
//                               data-category-postfix
//                               data-hide-listing-title-postfix
//                               data-hide-category-title-postfix="1"
//                               data-category-prefix
//                               data-meta-title
//                               data-meta-description
//                             >
//                               <a
//                                 className="filter-multi-select-list-item-link filter-property-select-list-item-link"
//                                 href
//                               >
//                                 <div className="form-check">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input filter-multi-select-checkbox"
//                                     data-label="Jackets"
//                                     value="018ea942f72f7f7e9d3d9da3f336434b"
//                                     id="018ea942f72f7f7e9d3d9da3f336434b"
//                                   />
//                                   <label
//                                     className="filter-multi-select-item-label form-check-label"
//                                     for="018ea942f72f7f7e9d3d9da3f336434b"
//                                   >
//                                     <span className="text-block">Jackets</span>
//                                   </label>
//                                 </div>
//                               </a>
//                             </li>
//                             <li
//                               className="filter-multi-select-list-item filter-property-select-list-item"
//                               data-id="018ea942ab777921a1b60714142ac22f"
//                               data-url-name="jerseys"
//                               data-title-name="jerseys"
//                               data-use-as-prefix
//                               data-category-postfix
//                               data-hide-listing-title-postfix
//                               data-hide-category-title-postfix="1"
//                               data-category-prefix=" "
//                               data-meta-title
//                               data-meta-description
//                             >
//                               <a
//                                 className="filter-multi-select-list-item-link filter-property-select-list-item-link"
//                                 href
//                               >
//                                 <div className="form-check">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input filter-multi-select-checkbox"
//                                     data-label="Jerseys"
//                                     value="018ea942ab777921a1b60714142ac22f"
//                                     id="018ea942ab777921a1b60714142ac22f"
//                                   />
//                                   <label
//                                     className="filter-multi-select-item-label form-check-label"
//                                     for="018ea942ab777921a1b60714142ac22f"
//                                   >
//                                     <span className="text-block">Jerseys</span>
//                                   </label>
//                                 </div>
//                               </a>
//                             </li>
//                             <li
//                               className="filter-multi-select-list-item filter-property-select-list-item"
//                               data-id="018ea943d2107e69bbe4291bbe07dd55"
//                               data-url-name="socks"
//                               data-title-name="socks"
//                               data-use-as-prefix
//                               data-category-postfix
//                               data-hide-listing-title-postfix
//                               data-hide-category-title-postfix="1"
//                               data-category-prefix
//                               data-meta-title
//                               data-meta-description
//                             >
//                               <a
//                                 className="filter-multi-select-list-item-link filter-property-select-list-item-link"
//                                 href
//                               >
//                                 <div className="form-check">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input filter-multi-select-checkbox"
//                                     data-label="Socks"
//                                     value="018ea943d2107e69bbe4291bbe07dd55"
//                                     id="018ea943d2107e69bbe4291bbe07dd55"
//                                   />
//                                   <label
//                                     className="filter-multi-select-item-label form-check-label"
//                                     for="018ea943d2107e69bbe4291bbe07dd55"
//                                   >
//                                     <span className="text-block">Socks</span>
//                                   </label>
//                                 </div>
//                               </a>
//                             </li>
//                             <li
//                               className="filter-multi-select-list-item filter-property-select-list-item"
//                               data-id="018ea9430cff7590a08d6246b1321503"
//                               data-url-name="sweatshirt"
//                               data-title-name="sweatshirts"
//                               data-use-as-prefix
//                               data-category-postfix
//                               data-hide-listing-title-postfix
//                               data-hide-category-title-postfix="1"
//                               data-category-prefix
//                               data-meta-title
//                               data-meta-description
//                             >
//                               <a
//                                 className="filter-multi-select-list-item-link filter-property-select-list-item-link"
//                                 href
//                               >
//                                 <div className="form-check">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input filter-multi-select-checkbox"
//                                     data-label="Sweatshirts"
//                                     value="018ea9430cff7590a08d6246b1321503"
//                                     id="018ea9430cff7590a08d6246b1321503"
//                                   />
//                                   <label
//                                     className="filter-multi-select-item-label form-check-label"
//                                     for="018ea9430cff7590a08d6246b1321503"
//                                   >
//                                     <span className="text-block">
//                                       Sweatshirts
//                                     </span>
//                                   </label>
//                                 </div>
//                               </a>
//                             </li>
//                             <li
//                               className="filter-multi-select-list-item filter-property-select-list-item"
//                               data-id="018ea942e12e7e818c8cce9feae7e6f8"
//                               data-url-name="tracksuits"
//                               data-title-name="tracksuits"
//                               data-use-as-prefix
//                               data-category-postfix
//                               data-hide-listing-title-postfix
//                               data-hide-category-title-postfix="1"
//                               data-category-prefix
//                               data-meta-title
//                               data-meta-description
//                             >
//                               <a
//                                 className="filter-multi-select-list-item-link filter-property-select-list-item-link"
//                                 href
//                               >
//                                 <div className="form-check">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input filter-multi-select-checkbox"
//                                     data-label="Tracksuits"
//                                     value="018ea942e12e7e818c8cce9feae7e6f8"
//                                     id="018ea942e12e7e818c8cce9feae7e6f8"
//                                   />
//                                   <label
//                                     className="filter-multi-select-item-label form-check-label"
//                                     for="018ea942e12e7e818c8cce9feae7e6f8"
//                                   >
//                                     <span className="text-block">
//                                       Tracksuits
//                                     </span>
//                                   </label>
//                                 </div>
//                               </a>
//                             </li>
//                             <li
//                               className="filter-multi-select-list-item filter-property-select-list-item"
//                               data-id="018ea942c8a7712c8217948d03b0326e"
//                               data-url-name="trousers"
//                               data-title-name="trousers"
//                               data-use-as-prefix
//                               data-category-postfix
//                               data-hide-listing-title-postfix
//                               data-hide-category-title-postfix="1"
//                               data-category-prefix
//                               data-meta-title
//                               data-meta-description
//                             >
//                               <a
//                                 className="filter-multi-select-list-item-link filter-property-select-list-item-link"
//                                 href
//                               >
//                                 <div className="form-check">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input filter-multi-select-checkbox"
//                                     data-label="Trousers"
//                                     value="018ea942c8a7712c8217948d03b0326e"
//                                     id="018ea942c8a7712c8217948d03b0326e"
//                                   />
//                                   <label
//                                     className="filter-multi-select-item-label form-check-label"
//                                     for="018ea942c8a7712c8217948d03b0326e"
//                                   >
//                                     <span className="text-block">Trousers</span>
//                                   </label>
//                                 </div>
//                               </a>
//                             </li>
//                             <li
//                               className="filter-multi-select-list-item filter-property-select-list-item"
//                               data-id="019097d43ff37a06a0ec8571bc70efdb"
//                               data-url-name="underwear"
//                               data-title-name="Underwear"
//                               data-use-as-prefix
//                               data-category-postfix
//                               data-hide-listing-title-postfix
//                               data-hide-category-title-postfix="1"
//                               data-category-prefix
//                               data-meta-title
//                               data-meta-description
//                             >
//                               <a
//                                 className="filter-multi-select-list-item-link filter-property-select-list-item-link"
//                                 href
//                               >
//                                 <div className="form-check">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input filter-multi-select-checkbox"
//                                     data-label="Underwear"
//                                     value="019097d43ff37a06a0ec8571bc70efdb"
//                                     id="019097d43ff37a06a0ec8571bc70efdb"
//                                   />
//                                   <label
//                                     className="filter-multi-select-item-label form-check-label"
//                                     for="019097d43ff37a06a0ec8571bc70efdb"
//                                   >
//                                     <span className="text-block">
//                                       Underwear
//                                     </span>
//                                   </label>
//                                 </div>
//                               </a>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>

//                       <div
//                         className="filter-multi-select filter-multi-select-properties filter-panel-item d-grid"
//                         data-filter-property-select="true"
//                         data-filter-property-select-options='{"name":"properties","propertyName":"Sleeve length","snippets":{"disabledFilterText":"This filter does not display any further results in combination with the selected filters."}}'
//                       >
//                         <button
//                           className="filter-panel-item-toggle btn"
//                           aria-expanded="false"
//                           data-bs-toggle="collapse"
//                           data-bs-target="#filter-properties-404020801"
//                         >
//                           Category
//                           <span className="filter-multi-select-count"></span>
//                           <span className="icon icon-arrow-medium-down icon-xs icon-filter-panel-item-toggle">
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               xlink="http://www.w3.org/1999/xlink"
//                               width="16"
//                               height="16"
//                               viewBox="0 0 16 16"
//                             >
//                               <use
//                                 href="#icons-solid-arrow-medium-down"
//                                 fill="#758CA3"
//                                 fill-rule="evenodd"
//                               />
//                             </svg>
//                           </span>
//                         </button>
//                         <div
//                           className="filter-multi-select-dropdown filter-panel-item-dropdown collapse"
//                           id="filter-properties-404020801"
//                         >
//                           <ul
//                             className="filter-multi-select-list"
//                             data-id="b88406c496504a24b2916cd5dcc92a5c"
//                             data-title-prefix
//                             data-title-postfix
//                             data-title-position="3"
//                             data-url-position="1"
//                             data-related-property-groups='["018ea94198d97fc78914e87756acdb9f"]'
//                             data-hide-from-title-on-related-property-groups="{}"
//                             data-hide-category-from-title="0"
//                           >
//                             <li
//                               className="filter-multi-select-list-item filter-property-select-list-item"
//                               data-id="f99452920d564f359b5a41bdc4cef088"
//                               data-url-name="long-sleeved"
//                               data-title-name="long-sleeved"
//                               data-use-as-prefix
//                               data-category-postfix
//                               data-hide-listing-title-postfix
//                               data-hide-category-title-postfix
//                               data-category-prefix
//                               data-meta-title
//                               data-meta-description
//                             >
//                               <a
//                                 className="filter-multi-select-list-item-link filter-property-select-list-item-link"
//                                 href
//                               >
//                                 <div className="form-check">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input filter-multi-select-checkbox"
//                                     data-label="Long-sleeved"
//                                     value="f99452920d564f359b5a41bdc4cef088"
//                                     id="f99452920d564f359b5a41bdc4cef088"
//                                   />
//                                   <label
//                                     className="filter-multi-select-item-label form-check-label"
//                                     for="f99452920d564f359b5a41bdc4cef088"
//                                   >
//                                     <span className="text-block">
//                                       Long-sleeved
//                                     </span>
//                                   </label>
//                                 </div>
//                               </a>
//                             </li>
//                             <li
//                               className="filter-multi-select-list-item filter-property-select-list-item"
//                               data-id="47172f3a172f413986ac03325b04115e"
//                               data-url-name="short-sleeved"
//                               data-title-name="short-sleeved"
//                               data-use-as-prefix
//                               data-category-postfix
//                               data-hide-listing-title-postfix
//                               data-hide-category-title-postfix
//                               data-category-prefix
//                               data-meta-title
//                               data-meta-description
//                             >
//                               <a
//                                 className="filter-multi-select-list-item-link filter-property-select-list-item-link"
//                                 href
//                               >
//                                 <div className="form-check">
//                                   <input
//                                     type="checkbox"
//                                     className="form-check-input filter-multi-select-checkbox"
//                                     data-label="Short-sleeved"
//                                     value="47172f3a172f413986ac03325b04115e"
//                                     id="47172f3a172f413986ac03325b04115e"
//                                   />
//                                   <label
//                                     className="filter-multi-select-item-label form-check-label"
//                                     for="47172f3a172f413986ac03325b04115e"
//                                   >
//                                     <span className="text-block">
//                                       Short-sleeved
//                                     </span>
//                                   </label>
//                                 </div>
//                               </a>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div
//                         className="filter-multi-select filter-multi-select-properties filter-panel-item d-grid"
//                         data-filter-property-select="true"
//                         data-filter-property-select-options='{"name":"price","propertyName":"Price","snippets":{"disabledFilterText":"This filter does not display any further results in combination with the selected filters."}}'
//                       >
//                         <button
//                           className="filter-panel-item-toggle btn"
//                           aria-expanded="false"
//                           data-bs-toggle="collapse"
//                           data-bs-target="#filter-properties-791505717"
//                         >
//                           Price Range
//                           <span className="filter-multi-select-count"></span>
//                           <span className="icon icon-arrow-medium-down icon-xs icon-filter-panel-item-toggle">
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               xlink="http://www.w3.org/1999/xlink"
//                               width="16"
//                               height="16"
//                               viewBox="0 0 16 16"
//                             >
//                               <use
//                                 href="#icons-solid-arrow-medium-down"
//                                 fill="#758CA3"
//                                 fill-rule="evenodd"
//                               />
//                             </svg>
//                           </span>
//                         </button>
//                         <div
//                           className="filter-multi-select-dropdown filter-panel-item-dropdown collapse"
//                           id="filter-properties-791505717"
//                         >
//                           <div className="price-range-inputs">
//                             <label for="min-price" className="form-label">
//                               Min Price
//                             </label>
//                             <input
//                               type="number"
//                               className="form-control"
//                               id="min-price"
//                               placeholder="Enter min price"
//                             />
//                             <label for="max-price" className="form-label mt-2">
//                               Max Price
//                             </label>
//                             <input
//                               type="number"
//                               className="form-control"
//                               id="max-price"
//                               placeholder="Enter max price"
//                             />
//                           </div>
//                           <button className="btn btn-primary mt-3 apply-button">
//                             Apply
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MenFilter;

// import React, { useState, useEffect } from "react";
// import "../../Styles/Filter.css";
// import { FaAngleDown } from "react-icons/fa6";
// import { FaAngleUp } from "react-icons/fa6";
// import { menuSlugSection } from "../../Services/Operations/ProductAPI";

// function MenFilter({ menu, categoryName }) {
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedSubcategory, setSelectedSubcategory] = useState("");
//   const [minPrice, setMinPrice] = useState("");
//   const [maxPrice, setMaxPrice] = useState("");
//   const [showCategoryDropdown, setShowCategoryDropdown] = useState(true);
//   const [showSubcategoryDropdown, setShowSubcategoryDropdown] = useState(true);
//   const [showPriceRange, setShowPriceRange] = useState(true);
//   const [menuSlug, setMenuSlug] = useState([]);

//   console.log("categoryName : ", categoryName);
//   console.log("menu : ", menu);

//   useEffect(() => {
//     const clickHandler = async () => {
//       try {
//         console.log("enter name : ", menu);
//         const formData = new FormData();
//         formData.append("slug", menu);
//         const response = await menuSlugSection(formData);
//         console.log("API response for MenFilter:", response.data.category);
//         if (response.success === true) {
//           setMenuSlug(response.data.category);
//         }
//       } catch (error) {
//         console.error("Error during API call:", error);
//       }
//     };

//     clickHandler();
//   }, [menu]);

//   const handleCategoryChange = (category) => {
//     setSelectedCategory(category);
//     setSelectedSubcategory("");
//   };

//   const applyFilter = () => {
//     console.log({
//       category: selectedCategory,
//       subcategory: selectedSubcategory,
//       minPrice,
//       maxPrice,
//     });
//     alert("Filters Applied!");
//   };

//   return (
//     <div className="filter-section">
//       <div className="filter-container">
//         <h3 className="filter-title">Filters</h3>
//         <div className="filter-dropdown">
//           <div
//             className="dropdown-header"
//             onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
//           >
//             <span className="filter-label1">Category</span>
//             {showCategoryDropdown ? <FaAngleUp /> : <FaAngleDown />}
//           </div>
//           {showCategoryDropdown && (
//             <select
//               id="category"
//               className="filter-select"
//               value={selectedCategory}
//               onChange={(e) => handleCategoryChange(e.target.value)}
//             >
//               <option value="">Select Category</option>
//               {menuSlug.map((category) => (
//                 <option
//                   key={category.id}
//                   value={category.name}
//                   style={{
//                     opacity: category.slug === categoryName ? 0.5 : 1,
//                     color: category.slug === categoryName ? "gray" : "black",
//                   }}
//                 >
//                   {category.name}
//                 </option>
//               ))}
//             </select>
//           )}
//         </div>

//         {menuSlug.length > 0 && (
//           <div className="filter-dropdown">
//             <div
//               className="dropdown-header"
//               onClick={() =>
//                 setShowSubcategoryDropdown(!showSubcategoryDropdown)
//               }
//             >
//               <span className="filter-label1">Subcategory</span>
//               {showSubcategoryDropdown ? <FaAngleUp /> : <FaAngleDown />}
//             </div>
//             {showSubcategoryDropdown && (
//               <select
//                 id="subcategory"
//                 className="filter-select"
//                 value={selectedSubcategory}
//                 onChange={(e) => setSelectedSubcategory(e.target.value)}
//               >
//                 <option value="">Select Subcategory</option>
//                 {menuSlug
//                   .filter((category) => category.name === selectedCategory)
//                   .map((category) =>
//                     category.subcategory.map((subcategory) => (
//                       <option key={subcategory.id} value={subcategory.name}>
//                         {subcategory.name}
//                       </option>
//                     ))
//                   )}
//               </select>
//             )}
//           </div>
//         )}

//         <div className="price-range">
//           <div
//             className="dropdown-header"
//             onClick={() => setShowPriceRange(!showPriceRange)}
//           >
//             <span className="filter-label1">Price Range</span>
//             {showPriceRange ? <FaAngleUp /> : <FaAngleDown />}
//           </div>
//           {showPriceRange && (
//             <div>
//               <label htmlFor="min-price" className="filter-label">
//                 Min Price
//               </label>
//               <input
//                 type="number"
//                 id="min-price"
//                 className="filter-input"
//                 placeholder="Enter minimum price"
//                 value={minPrice}
//                 onChange={(e) => setMinPrice(e.target.value)}
//               />
//               <label htmlFor="max-price" className="filter-label">
//                 Max Price
//               </label>
//               <input
//                 type="number"
//                 id="max-price"
//                 className="filter-input"
//                 placeholder="Enter maximum price"
//                 value={maxPrice}
//                 onChange={(e) => setMaxPrice(e.target.value)}
//               />
//             </div>
//           )}
//         </div>

//         <button className="filter-button" onClick={applyFilter}>
//           Apply Filters
//         </button>
//       </div>
//     </div>
//   );
// }

// export default MenFilter;


import React, { useState, useEffect } from "react";
import "../../Styles/Filter.css";
import { menuSlugSection } from "../../Services/Operations/ProductAPI";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";

function MenFilter({ menu, categoryName }) {
  const [selectedCategory, setSelectedCategory] = useState(categoryName || "");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(true);
  const [showSubcategoryDropdown, setShowSubcategoryDropdown] = useState(true);
  const [showPriceRange, setShowPriceRange] = useState(true);
  const [menuSlug, setMenuSlug] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const formData = new FormData();
        formData.append("slug", menu);
        const response = await menuSlugSection(formData);
        console.log("API response for MenFilter:", response.data.category);
        if (response.success === true) {
          setMenuSlug(response.data.category);

          if (categoryName) {
            const matchedCategory = response.data.category.find(
              (category) => category.slug === categoryName
            );
            if (matchedCategory) {
              setSelectedCategory(matchedCategory.name);
              setFilteredSubcategories(matchedCategory.subcategory);
            }
          }
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    fetchCategories();
  }, [menu, categoryName]);

  useEffect(() => {
    const matchedCategory = menuSlug.find(
      (category) => category.name === selectedCategory
    );
    setFilteredSubcategories(matchedCategory ? matchedCategory.subcategory : []);
  }, [selectedCategory, menuSlug]);

  const applyFilter = () => {
    console.log({
      category: selectedCategory,
      subcategory: selectedSubcategory,
      minPrice,
      maxPrice,
    });
    alert("Filters Applied!");
  };

  return (
    <div className="filter-section">
      <div className="filter-container">
        <h3 className="filter-title">Filters</h3>
        <div className="category-section">
          <div
            className="dropdown-header"
            onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
          >
            <h4 className="section-title">Category</h4>
            {showCategoryDropdown ? <FaAngleUp /> : <FaAngleDown />}
          </div>
          {showCategoryDropdown && (
            <div className="category-boxes">
              {menuSlug.map((category) => (
                <button
                  key={category.id}
                  className={`category-box ${
                    category.slug === categoryName ? "highlighted" : ""
                  } ${selectedCategory === category.name ? "selected" : ""}`}
                  onClick={() => {
                    setSelectedCategory(category.name);
                    setSelectedSubcategory(""); 
                  }}
                >
                  {category.name}
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="subcategory-section">
          <div
            className="dropdown-header"
            onClick={() =>
              setShowSubcategoryDropdown(!showSubcategoryDropdown)
            }
          >
            <h4 className="section-title">Subcategory</h4>
            {showSubcategoryDropdown ? <FaAngleUp /> : <FaAngleDown />}
          </div>
          {showSubcategoryDropdown && (
            <div className="subcategory-boxes">
              {filteredSubcategories.map((subcategory) => (
                <button
                  key={subcategory.id}
                  className={`subcategory-box ${
                    selectedSubcategory === subcategory.name ? "selected" : ""
                  }`}
                  onClick={() => setSelectedSubcategory(subcategory.name)}
                >
                  {subcategory.name}
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="price-section">
          <div
            className="dropdown-header"
            onClick={() => setShowPriceRange(!showPriceRange)}
          >
            <span className="section-title">Price Range</span>
            {showPriceRange ? <FaAngleUp /> : <FaAngleDown />}
          </div>
          {showPriceRange && (
            <div>
              <label htmlFor="min-price" className="filter-label">
                Min Price
              </label>
              <input
                type="number"
                id="min-price"
                className="filter-input"
                placeholder="Enter minimum price"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
              />
              <label htmlFor="max-price" className="filter-label">
                Max Price
              </label>
              <input
                type="number"
                id="max-price"
                className="filter-input"
                placeholder="Enter maximum price"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
            </div>
          )}
        </div>

        <button className="filter-button" onClick={applyFilter}>
          Apply Filters
        </button>
      </div>
    </div>
  );
}

export default MenFilter;
