import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import {
  getUser,
  orderValidate,
  PaymentAdd,
  PlaceOrder,
} from "../../Services/Operations/ProductAPI";
import "../../Styles/CheckOut.css";
import { deleteAllCart } from "../../Redux/Cart_System";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const { cartItems, totalPrice1, discountAmount, payableAmount } =
    location.state || {};

  console.log("cartItems :", cartItems);
  console.log("totalPrice1 :", totalPrice1);
  console.log("discountAmount :", discountAmount);
  console.log("payableAmount :", payableAmount);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [order, setOrder] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  const [addresses, setAddresses] = useState([]);

  const { tokenDetails } = useSelector((state) => state.user);
  const token = tokenDetails;

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  useEffect(() => {
    if (location.state && location.state.couponCode) {
      setCouponCode(location.state.couponCode);
    }
  }, [location.state]);
  
  useEffect(() => {
    console.log("couponCode updated: ", couponCode);
  }, [couponCode]);
  

  const [selectedId, setSelectedId] = useState(null);

  const handleClick = (id) => {
    setSelectedId(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUser(token);
        console.log("address :", response);
        if (response.success) {
          setAddresses(response.data);
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        toast.error("An error occurred while fetching addresses.");
      }
    };

    fetchData();
  }, []);

  const handlePay = async () => {
    setPaymentModalOpen(true);
  };

  const handleOk = async () => {
    const formData = new FormData();
    const filteredCartData = cartItems.map((item) => ({
      product_id: item.id,
      size_id: item.size_id,
      quantity: item.quantity,
    }));
    formData.append("cart", JSON.stringify(filteredCartData));
    formData.append("couponCode", couponCode);
    formData.append("orderType", "NORMAL");
    formData.append("paymentType", "COD");
    formData.append("created_by", "WEB");
    formData.append("addressId", selectedId);

    try {
      const placeOrderResponse = await PlaceOrder(formData, token);
      console.log("placeOrderResponse : ", placeOrderResponse);
      if (placeOrderResponse.success === true) {
        setPaymentModalOpen(false);
        toast.success("Order is completed");
        dispatch(deleteAllCart());
        navigate("/orderHistory");
      } else {
        toast.error("select address !");
        setPaymentModalOpen(false);
      }

      // if (placeOrderResponse.success === true) {
      //   // setOrderId(placeOrderResponse.data);
      //   setOrder(true);

      //   const paymentResponse = await PaymentAdd(
      //     placeOrderResponse.data,
      //     token
      //   );
      //   if (paymentResponse.success === true) {
      //     // setPaymentId(paymentResponse.data.paymentId);
      //     // setPaymentModalOpen(false);
      //     setOrder(false);

      //     // Razorpay payment process
      //     try {
      //       const options = {
      //         key: "rzp_test_q8NNolcCI53Vdo",
      //         amount: totalPrice * 100,
      //         currency: "INR",
      //         name: "E-commerce Website",
      //         description: "Test Transaction",
      //         image: "https://example.com/your_logo",
      //         order_id: paymentResponse.data.paymentId,
      //         handler: async function (response) {
      //           try {
      //             const validationResponse = await orderValidate(
      //               {
      //                 paymentId: response.razorpay_payment_id,
      //                 orderId: placeOrderResponse.data.orderId,
      //               },
      //               token
      //             );

      //             if (validationResponse.success === true) {
      //               // dispatch(deleteAllCart());
      //               toast.success("Order successfully completed");
      //               // navigate("/profile");
      //             } else {
      //               alert("Payment validation failed. Please contact support.");
      //             }
      //           } catch (error) {
      //             console.error("Error validating payment:", error);
      //           }
      //         },
      //         prefill: {
      //           name: "Saifuddin Mondal",
      //           email: "saifuddin@example.com",
      //           contact: "9564779055",
      //         },
      //         notes: {
      //           address: "Razorpay Corporate Office",
      //         },
      //         theme: {
      //           color: "#3399cc",
      //         },
      //       };

      //       const paymentObject = new window.Razorpay(options);
      //       paymentObject.open();
      //     } catch (error) {
      //       alert("Error creating order. Please try again.");
      //       console.error(error);
      //     }
      //   }
      // } else {
      //   toast.error("Failed to place order. Please try again.");
      // }
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };

  return (
    <div>
      <div
        style={{
          padding: "20px",
          maxWidth: "1280px",
          margin: "auto",
          fontFamily: "Open Sans, sans-serif",
          color: "#333",
        }}
      >
        <h2
          style={{
            color: "#333",
            textAlign: "center",
            borderBottom: "3px solid #4CAF50",
            paddingBottom: "10px",
          }}
        >
          Checkout
        </h2>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              flex: "1",
              minWidth: "280px",
              backgroundColor: "#f9f9f9",
              padding: "15px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <h3
              style={{
                color: "#333",
                marginBottom: "10px",
                fontFamily: "sans-serif",
                fontWeight: "bold",
              }}
            >
              Cart Items
            </h3>
            {cartItems.map((item) => (
              <div
                key={item.id}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: "15px",
                  borderRadius: "8px",
                  marginBottom: "12px",
                  backgroundColor: "#fff",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                }}
              >
                <img
                  src={`https://sheildsports.com/main/public/${item.gallery[0].image}`}
                  alt={item.title}
                  style={{
                    width: "12%",
                        height: "118px",
                    marginRight: "15px",
                    borderRadius: "8px",
                    objectFit: "fill",
                  }}
                />
                <div style={{ flex: 1,width: "55%",}}>
                  <h4
                    style={{
                      margin: "0 0 5px",
                      color: "#333",
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.name}
                  </h4>
                  <p style={{ margin: "0", color: "#555" }}>
                    Price: €{(item.price * item.quantity).toFixed(2)}
                  </p>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1em",
                        fontWeight: "500",
                        color: "#333",
                      }}
                    >
                      Quantity : {item.quantity}
                    </span>
                  </div>
                </div>
              </div>
            ))}
            {/* <div
              style={{
                marginTop: "20px",
                fontWeight: "bold",
                fontSize: "1.4em",
                color: "#333",
              }}
            >
              Total: €{totalPrice.toFixed(2)}
            </div> */}
            <div style={styles.container}>
              <p style={styles.row}>
                <span style={styles.label}>Total Price:</span>
                <span style={styles.value}>€{totalPrice.toFixed(2)}</span>
              </p>
              <p style={styles.row}>
                <span style={styles.label}>Discount Amount:</span>
                <span
                  style={{
                    ...styles.value,
                    color: discountAmount ? "#ff4d4f" : "#555",
                  }}
                >
                  {discountAmount ? `- €${discountAmount.toFixed(2)}` : "€0.00"}
                </span>
              </p>
              <p style={styles.row}>
                <span style={styles.label}>Payable Amount:</span>
                <span
                  style={{
                    ...styles.value,
                    fontWeight: "bold",
                    color: "#28a745",
                  }}
                >
                  €{payableAmount.toFixed(2)}
                </span>
              </p>
            </div>
          </div>
          <div
            style={{
              flex: "1",
              minWidth: "280px",
              backgroundColor: "#f9f9f9",
              padding: "15px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <h3
              style={{
                color: "#333",
                marginBottom: "10px",
                fontFamily: "sans-serif",
              }}
            >
              Delivery Address
            </h3>
            {/* <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="Street"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="Address Line"
              value={addressLine}
              onChange={(e) => setAddressLine(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              style={inputStyle}
            />
            <input
              type="text"
              placeholder="Pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              style={inputStyle}
            />

            <button
              onClick={handlePay}
              style={{
                marginTop: "20px",
                padding: "14px",
                width: "100%",
                backgroundColor: "#4CAF50",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                fontSize: "1.1em",
                fontWeight: "bold",
              }}
            >
              Proceed to Pay
            </button> */}
            <div style={{ margin: "0px", fontFamily: "Arial, sans-serif" }}>
              {addresses.map((item) => (
                <div
                  key={item.id}
                  onClick={() => handleClick(item.id)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "15px 7px",
                    margin: "10px 0",
                    borderRadius: "8px",
                    cursor: "pointer",
                    backgroundColor:
                      selectedId === item.id ? "#d9f7ff" : "#f8f9fa",
                    border:
                      selectedId === item.id
                        ? "2px solid #00bcd4"
                        : "1px solid #e0e0e0",
                    boxShadow:
                      selectedId === item.id
                        ? "0 4px 8px rgba(0, 188, 212, 0.3)"
                        : "0 2px 4px rgba(0, 0, 0, 0.1)",
                    transition: "all 0.3s ease-in-out",
                  }}
                >
                  <div style={{ fontWeight: "bold", color: "#333" }}>
                    {item.name}
                  </div>
                  <div
                    style={{ flexGrow: 1, marginLeft: "13px", color: "#555" }}
                  >
                    {item.address}, {item.city},{item.state},{item.country},{" "}
                    {item.pincode}
                  </div>
                  <div style={{ fontWeight: "bold", color: "#007bff" }}>
                    {item.mobile_no}
                  </div>
                </div>
              ))}
              <button
                onClick={handlePay}
                style={{
                  marginTop: "44px",
                  padding: "14px",
                  width: "100%",
                  backgroundColor: "#4CAF50",
                  color: "#fff",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fontSize: "1.1em",
                  fontWeight: "bold",
                }}
              >
                Proceed to Pay
              </button>
            </div>
          </div>
        </div>
      </div>

      {isPaymentModalOpen && (
        <div className="payment-modal">
          <div className="payment-modal-content">
            <h2>Order Successful!</h2>
            <p>Your order is ready to be Proceed.</p>
            <button onClick={handleOk} className="pay-now-btn">
              Ok
            </button>
            <button
              onClick={() => {
                setPaymentModalOpen(false);
                setOrder(false);
              }}
              className="close-btn"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const inputStyle = {
  width: "100%",
  padding: "12px",
  margin: "8px 0",
  borderRadius: "8px",
  border: "1px solid #ddd",
  boxShadow: "inset 0 1px 3px rgba(0,0,0,0.1)",
};
const styles = {
  container: {
    width:"100%",
    backgroundColor: "#f9f9f9",
    padding: "20px",
    // margin: "20px auto",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    // maxWidth: "500px",
    fontFamily: "Arial, sans-serif",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    fontSize: "16px",
  },
  label: {
    fontWeight: "bold",
    color: "#555",
  },
  value: {
    color: "#333",
  },
};


export default Checkout;
