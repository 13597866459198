import React,{useState} from "react";
import Header from "../Components/Header";
import SimilarProducts from "../DetailsExtra/SimilarProducts";
import DetailsTab from "../DetailsExtra/DetailsTab";
import MiddleImage from "../DetailsExtra/MiddleImage";
import DetailsSizes from "../DetailsExtra/DetailsSizes";
import VerticalSlider from "../DetailsExtra/VerticalSlider";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeCart } from "../../Redux/Cart_System";
import '../../Styles/Details.css'

function DetailsPage() {
  const dispatch = useDispatch();
  const cartItemsFromRedux = useSelector((state) => state.cart.items);
  const totalItems = useSelector((state) => state.cart.totalItems);
  const [cartItems, setCartItems] = useState(cartItemsFromRedux);
  const location = useLocation();
  const particularProduct = location.state?.particularProduct;
  console.log("product : ",particularProduct)

  const handleIncrement = (id) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (id) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const handleRemove = (id) => {
    dispatch(removeCart(id));
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== id)); 
  };

  return (
    <div>
      <div style={{height:"56px"}}>
      <Header />
      </div>
      <main className="content-main">
        <div className="container">
          <div className="container-main">
            <div
              className="product-detail"
              itemscope
              itemtype="https://schema.org/Product"
            >
              <div
                className="product-detail-content bgweb"
                data-product-id="c0386fdb388b4c2aadc9adb5af5be91e"
                data-product-number="781001.SPIZED_COLORSCRATCH"
              >
                <div
                  className="row product-detail-main block-container-2"
                  style={{ display: "flex", flexDirection: "row",justifyContent: "space-between",
                    alignItems: "flex-start" }}
                >
                  <VerticalSlider product={particularProduct}/>
                  <MiddleImage product={particularProduct}/>
                  <DetailsSizes product={particularProduct}/>
                </div>
              </div>
              <DetailsTab product={particularProduct}/>
              <SimilarProducts product={particularProduct}/>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default DetailsPage;
