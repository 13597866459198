import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCart,
  removeCart,
  updateCartQuantity,
} from "../../Redux/Cart_System";
import "../../Styles/Details.css";
import { stockCheck } from "../../Services/Operations/ProductAPI";
import { toast } from "react-toastify";

function DetailsSizes({ product }) {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  console.log("product size : ", product);
  const [selectedSize, setSelectedSize] = useState(null);

  const [selectedPrice, setSelectedPrice] = useState("");

  useEffect(() => {
    if (product.size.length > 0 && selectedSize) {
      const price =
        product.size.find((s) => s.size_id === selectedSize)?.price || 0;
      console.log("Price : ", price);
      setSelectedPrice(price);
      handleOrder();
    }
  }, [selectedSize, product.size]);

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    const itemInCart = cartItems.find((item) => item.id === product.id);
    if (itemInCart) {
      setQuantity(itemInCart.quantity);
    } else {
      setQuantity(0);
    }
  }, [cartItems, product.id]);

  const handleOrder = async () => {
    if (quantity === 0) {
      try {
        const formData = new FormData();
        formData.append("product_id", product.id);
        formData.append("size_id", selectedSize);
        formData.append("quantity", 1);

        const response = await stockCheck(formData);
        console.log("API response for product details:", response);

        if (response.success === true) {
          dispatch(
            addCart({
              id: product.id,
              name: product.name,
              gallery: product.gallery,
              // quantity: product.quantity,
              price:
                product.size.find((s) => s.size_id === selectedSize)?.price ||
                0,
              // price:selectedPrice,
              quantity: 1,
              size_id: selectedSize,
            })
          );
          setQuantity(1);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error during product API call:", error);
      }
    } else {
      dispatch(
        updateCartQuantity({
          id: product.id,
          quantity: quantity,
          // price: selectedPrice,
          price:
            product.size.find((s) => s.size_id === selectedSize)?.price ||
            0,
        })
      );
      setQuantity(quantity);
    }
  };

  const handleIncrement = async () => {
    const newQuantity = quantity + 1;
    try {
      const formData = new FormData();
      formData.append("product_id", product.id);
      formData.append("size_id", selectedSize);
      formData.append("quantity", newQuantity);

      const response = await stockCheck(formData);
      console.log("API response for product details:", response);

      if (response.success === true) {
        setQuantity(newQuantity);
        console.log("quantity : ", newQuantity);
        dispatch(
          updateCartQuantity({
            id: product.id,
            quantity: newQuantity,
            // price: selectedPrice,
            price:
            product.size.find((s) => s.size_id === selectedSize)?.price ||
            0,
          })
        );
      } else {
        toast.error(`Stock not available (Available: ${response.data.stock})`);
      }
    } catch (error) {
      console.error("Error during product API call:", error);
    }
  };

  const handleDecrement = async () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      try {
        const formData = new FormData();
        formData.append("product_id", product.id);
        formData.append("size_id", selectedSize);
        formData.append("quantity", newQuantity);

        const response = await stockCheck(formData);
        console.log("API response for product details:", response);

        if (response.success === true) {
          setQuantity(newQuantity);
          console.log("quantity : ", newQuantity);
          dispatch(
            updateCartQuantity({
              id: product.id,
              quantity: newQuantity,
              // price: selectedPrice,
              price:
              product.size.find((s) => s.size_id === selectedSize)?.price ||
              0,
            })
          );
        } else {
          toast.error(
            `Stock not available (Available: ${response.data.stock})`
          );
        }
      } catch (error) {
        console.error("Error during product API call:", error);
      }
    } else if (quantity === 1) {
      dispatch(removeCart(product.id));
      setQuantity(0);
    }
  };

  return (
    <div style={{ maxWidth: "330px" }}>
      <div
        className="col-lg-6 product-detail-buy"
        data-detail-description="true"
        style={{ width: "100%" }}
      >
        <div className="col-md-auto product-detail-manufacturer">
          <a
            href
            className="product-detail-manufacturer-link"
            rel="noreferrer noopener"
            target="_blank"
            title="spized"
          >
            {/* <img
              src="../../../media/29/9b/3a/1639408455/element-2.svg"
              className="product-detail-manufacturer-logo"
              alt="spized"
            /> */}
          </a>
        </div>
        <div className="row align-items-center product-detail-headline">
          <div className="col product-detail-name-container">
            <h1 className="product-detail-name t2" itemprop="name">
              {product.name}
            </h1>
          </div>
        </div>
        <div className="description-box">
          <div className="description-box-short">
            <p>{product.short_description}</p>
          </div>
          <div className="description-box-long is--hidden">
            <p>{product.description}</p>
          </div>
          {/* <button className="description-show-more">show more</button>
          <button className="description-show-less is--hidden">
            show less
          </button> */}
        </div>
        <div className="js-magnifier-zoom-image-container">
          <div itemprop="brand" itemtype="https://schema.org/Brand" itemscope>
            <div itemprop="name" content="spized" />
          </div>
          <div itemprop="releaseDate" content="2024-10-16" />
          <div
            itemprop="offers"
            itemscope
            itemtype="http://schema.org/AggregateOffer"
          >
            <div itemprop="url" content="781001.html" />
            <div itemprop="lowPrice" content="29.9" />
            <div itemprop="highPrice" content="56.9" />
            <div itemprop="offerCount" content="9" />
            <div itemprop="priceCurrency" content="EUR" />
            <div
              className="product-detail-price-container f_pricesShortContainer"
              data-product-id="c0386fdb388b4c2aadc9adb5af5be91e"
            >
              <div className="prices-range-price-block">
                <div className="product--price">
                  <div className="price--content content--default">
                    <span className="price-line">
                      Unit price
                      <span className="f_spizedArticleCount">1</span> pcs:
                      <span className="f_spizedArticlePrice article-price">
                        € {selectedPrice}
                      </span>
                    </span>
                    {/* <span className="f_spizedArticlePricePercentage article-price-percentage">
                      35 % Quantity discount
                    </span> */}
                  </div>
                </div>
              </div>{" "}
            </div>
            {/* <a className="change-quantity-link">Change quantity</a> */}
            {/* <div className="js-product--unit-price product--unit-price is--hidden">
              <div className="price--content content--default">
                Unit price for
                <span className="f_spizedUnitArticleCount"> 1 </span>
                pcs.:
                <span className="f_spizedArticleUnitPrice article-unit-price">
                  {product.unitPriceFor1}
                </span>
              </div>
            </div> */}
            <div className="product-detail-tax-container">
              <p className="product-detail-tax">
                {/* <a className="product-detail-tax-link go-shipping-tab">
                  Prices incl. VAT plus shipping costs
                </a> */}
              </p>
            </div>
            <div className="product-detail-delivery-information">
              <div className="product-delivery-information"></div>
            </div>
            <div className="product-detail-form-container desktop-view">
              <div
                id="productDetailPageBuyProductForm"
                className="row g-2 buy-widget-container"
              >
                {/* <a
                  href
                  className="btn btn-primary btn-block btn-buy button2 f_customize-button"
                  title="Add To Cart"
                  aria-label="Add To Cart"
                  target="_blank"
                >
                  Add to cart
                </a> */}
                {quantity === 0 ? (
                  <button className="section2-one-btn" onClick={handleOrder}>
                    Add to Cart
                  </button>
                ) : (
                  <div className="section-btn">
                    <button
                      className="section-add-sub"
                      onClick={handleDecrement}
                    >
                      -
                    </button>
                    <span>{quantity}</span>
                    <button
                      className="section-add-sub"
                      onClick={handleIncrement}
                    >
                      +
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="social-proof">
          <div className="social-proof-logo">
            <svg className="ico-svg">
              <use href="#spized-logo-small"></use>
            </svg>
          </div>
          <div className="social-proof-text">
            Over <strong>150.000 customers</strong> worldwide are already
            achieving new personal bests with their customized products from
            spized
            <svg className="ico-svg">
              <use href="#spized-logo"></use>
            </svg>
          </div>
        </div>{" "}
        <div className="delivery-content">
          <div className="delivery-content-logo">
            <span className="icon icon-heart icon-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <defs>
                  <path
                    d="M20.0139 12.2998c1.8224-1.8224 1.8224-4.7772 0-6.5996-1.8225-1.8225-4.7772-1.8225-6.5997 0L12 7.1144l-1.4142-1.4142c-1.8225-1.8225-4.7772-1.8225-6.5997 0-1.8224 1.8224-1.8224 4.7772 0 6.5996l7.519 7.519a.7.7 0 0 0 .9899 0l7.5189-7.519zm1.4142 1.4142-7.519 7.519c-1.0543 1.0544-2.7639 1.0544-3.8183 0L2.572 13.714c-2.6035-2.6035-2.6035-6.8245 0-9.428 2.6035-2.6035 6.8246-2.6035 9.4281 0 2.6035-2.6035 6.8246-2.6035 9.428 0 2.6036 2.6035 2.6036 6.8245 0 9.428z"
                    id="icons-default-heart"
                  />
                </defs>
                <use
                  href="#icons-default-heart"
                  fill="#758CA3"
                  fill-rule="evenodd"
                />
              </svg>
            </span>
          </div>
          <div className="delivery-content-info">
            <div className="delivery-content-title">
              All printing costs included!
            </div>
            <div className="delivery-content-description">
              Logos, Names, Numbers &amp; Inscriptions
            </div>
          </div>
        </div>{" "}
        <div className="jumplink-container">
          <div className="jumplink--shipping jumplink-content">
            <div className="jumplink--shipping-icon jumplink-icon">
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  clip-path="url(#a)"
                  stroke="#000"
                  stroke-width="1.714"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M12 .857v6.857M14.571 18.857h4.286M.857 7.714h22.286v13.714a1.714 1.714 0 0 1-1.715 1.715H2.572a1.714 1.714 0 0 1-1.714-1.715V7.714ZM.857 7.714 3.43 2.76A3.428 3.428 0 0 1 6.41.857H17.59a3.429 3.429 0 0 1 3.068 1.903l2.486 4.954" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="jumplink--shipping-info jumplink-info">
              <div className="jumplink-title">How long does it take?</div>
              <div style={{ fontSize: "13px" }}>
                {product.expectedDeliveryDate}
              </div>
            </div>
            <div className="jumplink--shipping-link jumplink">
              <a className="go-shipping-tab">Shipping costs</a>
            </div>
          </div>
          <div className="jumplink--sizes jumplink-content">
            <div className="jumplink--sizes-icon jumplink-icon">
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  clip-path="url(#a)"
                  stroke="#000"
                  stroke-width="1.714"
                  stroke-linejoin="round"
                >
                  <path d="M15.027 7.486H8.87a1.47 1.47 0 0 0-1.471 1.471v6.158c0 .812.658 1.47 1.47 1.47h6.158a1.47 1.47 0 0 0 1.471-1.47V8.957a1.47 1.47 0 0 0-1.47-1.47Z" />
                  <path
                    d="M.857 7.358v3.518M.857 14.98v1.173c0 .647.525 1.172 1.173 1.172h1.173M7.307.91h3.519M14.93.91h1.173c.648 0 1.173.524 1.173 1.172v1.173M.857 3.255V2.082c0-.648.525-1.173 1.173-1.173h1.173M20.506 6.673h1.166c.813 0 1.47.658 1.47 1.47v13.478a1.47 1.47 0 0 1-1.47 1.47H8.194a1.47 1.47 0 0 1-1.47-1.47v-.732"
                    stroke-linecap="round"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="jumplink--sizes-info jumplink-info">
              <div className="jumplink-title" style={{ marginBottom: "5px" }}>
                Available sizes?
              </div>
              <div>
                {product.size.map((size, index) => (
                  <span
                    key={index}
                    onClick={() => handleSizeClick(size.size_id)}
                    style={{
                      margin: "0 5px",
                      padding: "5px",
                      border: "1px solid #ddd",
                      cursor: "pointer",
                      backgroundColor:
                        selectedSize === size.size_id ? "#007BFF" : "#fff",
                      color: selectedSize === size.size_id ? "#fff" : "#000",
                    }}
                  >
                    {size.name}
                  </span>
                ))}
              </div>
            </div>
            <div className="jumplink--sizes-link jumplink">
              <a className="go-sizes-tab">Size table</a>
            </div>
          </div>
          <div className="jumplink--sample-orders jumplink-content">
            <div className="jumplink--sample-orders-icon jumplink-icon">
              <svg
                width="24"
                height="24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  clip-path="url(#a)"
                  stroke="#000"
                  stroke-width="1.714"
                  stroke-linejoin="round"
                >
                  <path d="M.857 13.592c0 .879.713 1.592 1.592 1.592h11.143c.879 0 1.592-.713 1.592-1.592V2.449c0-.88-.713-1.592-1.592-1.592H2.449C1.569.857.857 1.57.857 2.45v11.143Z" />
                  <path d="M15.184 8.816h6.367c.88 0 1.592.713 1.592 1.592v11.143c0 .88-.713 1.592-1.592 1.592H10.408c-.879 0-1.592-.713-1.592-1.592v-6.367" />
                  <path
                    d="M1.317 6.18a.462.462 0 0 1-.46-.463c0-.256.206-.464.46-.464M1.325 6.18c.254 0 .46-.207.46-.463a.462.462 0 0 0-.46-.464M11.399 10.498a.462.462 0 0 1-.46-.464c0-.256.206-.464.46-.464M11.407 10.498c.254 0 .46-.207.46-.464a.462.462 0 0 0-.46-.464M4.355 10.498a.462.462 0 0 1-.46-.464c0-.256.206-.464.46-.464M4.363 10.498c.254 0 .46-.207.46-.464a.462.462 0 0 0-.46-.464M8.016 6.18a.462.462 0 0 1-.46-.463c0-.256.206-.464.46-.464M8.024 6.18c.254 0 .46-.207.46-.463a.462.462 0 0 0-.46-.464M4.35 2.307a.462.462 0 0 1-.46-.464c0-.256.207-.464.46-.464M4.359 2.307c.254 0 .46-.208.46-.464a.462.462 0 0 0-.46-.464M7.803 14.256a.462.462 0 0 1-.46-.464c0-.256.206-.464.46-.464M7.811 14.256c.254 0 .46-.208.46-.464a.462.462 0 0 0-.46-.464M1.777 14.256a.462.462 0 0 1-.46-.464c0-.256.206-.464.46-.464M1.785 14.256c.254 0 .46-.208.46-.464a.462.462 0 0 0-.46-.464M11.403 2.307a.462.462 0 0 1-.46-.464c0-.256.206-.464.46-.464M11.411 2.307c.254 0 .46-.208.46-.464a.462.462 0 0 0-.46-.464M14.289 6.18a.462.462 0 0 1-.46-.463c0-.256.206-.464.46-.464M14.297 6.18c.254 0 .46-.207.46-.463a.462.462 0 0 0-.46-.464M13.83 14.256a.462.462 0 0 1-.46-.464c0-.256.205-.464.46-.464M13.837 14.256c.254 0 .46-.208.46-.464a.462.462 0 0 0-.46-.464"
                    stroke-linecap="round"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="jumplink--sample-orders-info jumplink-info">
              <div className="jumplink-title">
                Can I get samples in advance?
              </div>
              <div>Request free sample now</div>
            </div>
            <div className="jumplink--sample-orders-link jumplink">
              <a
                className="go-sample-orders-page"
                target="_blank"
                href="../../sample-order.html"
              >
                Request sample
              </a>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default DetailsSizes;
