import React, { useState } from "react";
import Discover from "../Components/Discover";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  addCart,
  removeCart,
  updateCartQuantity,
} from "../../Redux/Cart_System";
import { useNavigate } from "react-router-dom";
import { Coupon_data, stockCheck } from "../../Services/Operations/ProductAPI";
import { toast } from "react-toastify";
import "../../Styles/Cart.css";

const CartSec = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [couponMessage, setCouponMessage] = useState("");
  const cartItemsFromRedux = useSelector((state) => state.cart.items);
  console.log("cartItemsFromRedux : ", cartItemsFromRedux);
  const { tokenDetails } = useSelector((state) => state.user);
  const token = tokenDetails;

  const handleIncrement = async (product) => {
    console.log("increment product : ",product)
    const newQuantity = product.quantity + 1;
    try {
      const formData = new FormData();
      formData.append("product_id", product.id);
      formData.append("size_id", product.size_id);
      formData.append("quantity", newQuantity);

       const response = await stockCheck(formData);
      console.log("API response for product details:", response);

      if (response.success === true) {
        setQuantity(newQuantity);
        console.log("quantity : ", newQuantity);
        dispatch(updateCartQuantity({ id: product.id, quantity: newQuantity, price: product.price }));
      } else {
        toast.error(`Stock not available (Available: ${response.data.stock})`);
      }
    } catch (error) {
      console.error("Error during product API call:", error);
    }
  };

  const handleDecrement = async (product) => {
    console.log("decrement product : ",product)
    if (product.quantity > 1) {
      const newQuantity = product.quantity - 1;
      try {
        const formData = new FormData();
        formData.append("product_id", product.id);
        formData.append("size_id", product.size_id);
        formData.append("quantity", newQuantity);

         const response = await stockCheck(formData);
        console.log("API response for product details:", response);

        if (response.success === true) {
          setQuantity(newQuantity);
          console.log("quantity : ", newQuantity);
          dispatch(
            updateCartQuantity({ id: product.id, quantity: newQuantity, price: product.price })
          );
        } else {
          toast.error(
            `Stock not available (Available: ${response.data.stock})`
          );
        }
      } catch (error) {
        console.error("Error during product API call:", error);
      }
      // const newQuantity = product.quantity - 1;
      // setQuantity(newQuantity);
      // console.log("quantity : ", newQuantity);
      // dispatch(updateCartQuantity({ id: product.id, quantity: newQuantity }));
    } else if (product.quantity === 1) {
      dispatch(removeCart(product.id));
      setQuantity(0);
    }
  };

  const removeItem = (id) => {
    dispatch(removeCart(id));
    setQuantity(0);
  };

  const handleProceedToCheckout = () => {
    if(!token){
      navigate("/auth")
    }
    else{
      console.log("payableAmount being passed:", payableAmount);
    navigate("/checkout", {
      state: {
        cartItems: cartItemsFromRedux,
        totalPrice1: totalPrice,
        discountAmount: discountAmount,
        payableAmount: payableAmount,
        couponCode,
      },
    });
    }
  };

  const totalPrice = cartItemsFromRedux.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const applyCoupon = async () => {
    try {
      const formData = new FormData();
      formData.append("couponCode", couponCode);
      formData.append("totalAmount", totalPrice);
      const result = await Coupon_data(formData, token);
      if (result.success === true) {
        setCouponMessage(result.data[0].description);
        setDiscountAmount(result.data[0].discountAmount);
        toast.success("Coupon applied successfully");
      } else {
        setCouponMessage("Invalid coupon code.");
        setDiscountAmount(0);
        toast.error("Invalid coupon code");
      }
    } catch (error) {
      toast.error("please login (token invalid)");
      console.error("Error applying coupon:", error);
    }
  };

  const payableAmount = discountAmount
    ? totalPrice - discountAmount
    : totalPrice;

  return (
    <div>
      <Header />
      <div
        style={{
          padding: "20px",
          maxWidth: "1280px",
          margin: "auto",
          fontFamily: "Open Sans, sans-serif",
          color: "#333",
        }}
      >
        <h2
          style={{
            color: "#333",
            textAlign: "center",
            borderBottom: "3px solid #4CAF50",
            paddingBottom: "10px",
          }}
        >
          Shopping Cart
        </h2>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          {/* Left side: Cart Items */}
          {cartItemsFromRedux.length === 0 ? (
            <div
              style={{
                flex: "1",
                minWidth: "280px",
                backgroundColor: "#f9f9f9",
                padding: "15px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                textAlign: "center",
              }}
            >
              <h3
                style={{
                  color: "#ff5c5c",
                  fontSize: "1.8em",
                  fontWeight: "600",
                }}
              >
                Your cart is empty
              </h3>
              <p
                style={{
                  color: "#555",
                  fontSize: "1.2em",
                }}
              >
                Looks like you haven't added anything to your cart yet.
              </p>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "#f9f9f9",
                padding: "15px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                width: "100%",
              }}
            >
              {cartItemsFromRedux.map((item) => {
                return (
                  <div
                    key={item.id}
                    style={{
                      width: "100%", // Use full width for each item
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "8px",
                      marginBottom: "12px",
                      flexDirection: "row", // Default to row for larger screens
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={`https://sheildsports.com/main/public/${item.gallery[0].image}`}
                      alt={item.title}
                      style={{
                        width: "12%",
                        height: "118px",
                        marginRight: "15px",
                        borderRadius: "8px",
                        objectFit: "fill",
                      }}
                    />
                    <div
                      style={{
                        flex: 1,
                        width: "auto",
                        paddingRight: "10px",
                        width: "55%",
                      }}
                    >
                      <h4
                        style={{
                          margin: "0 0 5px",
                          color: "#333",
                          fontWeight: "600",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.name}
                      </h4>
                      <p style={{ margin: "0", color: "#555" }}>
                        Price: €{(item.price * item.quantity).toFixed(2)}
                      </p>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <button
                          onClick={() => handleDecrement(item)}
                          style={{
                            backgroundColor: "#e0e0e0",
                            border: "none",
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            marginRight: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </button>
                        <span
                          style={{
                            margin: "0 12px",
                            fontSize: "1em",
                            fontWeight: "500",
                            color: "#333",
                          }}
                        >
                          {item.quantity}
                        </span>
                        <button
                          onClick={() => handleIncrement(item)}
                          style={{
                            backgroundColor: "#e0e0e0",
                            border: "none",
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            marginLeft: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <button
                      onClick={() => removeItem(item.id)}
                      style={{
                        backgroundColor: "#ff5c5c",
                        color: "#fff",
                        border: "none",
                        padding: "8px 12px",
                        cursor: "pointer",
                        borderRadius: "6px",
                        fontWeight: "bold",
                        marginLeft: "10px",
                        width: "fit-content",
                        marginTop: "10px", // Ensure it's aligned properly
                      }}
                    >
                      Remove
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {cartItemsFromRedux.length !== 0 ? (
            <div
              style={{
                width: "100%",
                margin: "20px auto",
                padding: "20px",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                fontFamily: "Arial, sans-serif",
              }}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    marginBottom: "15px",
                    textAlign: "center",
                  }}
                >
                  <h3 style={{ margin: "0", fontSize: "18px", color: "#333" }}>
                    Apply Coupon Code
                  </h3>
                  <p
                    style={{ margin: "5px 0", fontSize: "14px", color: "#777" }}
                  >
                    Enter your coupon code below to avail discounts.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter Coupon Code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    style={{
                      flex: "1",
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      fontSize: "14px",
                      color: "#555",
                      boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.05)",
                    }}
                  />
                  <button
                    type="button"
                    onClick={applyCoupon}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "rgb(76, 175, 80)",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "bold",
                      transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "rgb(36, 111,39)")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "rgb(76, 175, 80)")
                    }
                  >
                    Apply Coupon
                  </button>
                </div>
              </form>
            </div>
          ) : (
            ""
          )}
          {cartItemsFromRedux.length !== 0 ? (
            <div
              style={{
                width: "100%",
                padding: "20px",
                backgroundColor: "#f9f9f9",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                fontFamily: "Arial, sans-serif",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#333",
                }}
              >
                Total Price
                <span style={{ color: "#007bff" }}>
                  {totalPrice.toFixed(2)}
                </span>
              </p>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#555",
                }}
              >
                Discount Amount
                <span style={{ color: discountAmount ? "#ff4d4f" : "#aaa" }}>
                  {discountAmount ? `- ${discountAmount}` : 0}
                </span>
              </p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#333",
                  borderTop: "1px solid #ddd",
                  paddingTop: "10px",
                }}
              >
                Payable Amount {discountAmount ? "(5% discount)" : ""}
                <span style={{ color: "#28a745" }}>
                  {payableAmount.toFixed(2)}
                </span>
              </p>
            </div>
          ) : (
            ""
          )}

          {cartItemsFromRedux.length === 0 ? (
            ""
          ) : (
            <button
              style={{
                marginTop: "20px",
                padding: "14px",
                width: "100%",
                backgroundColor: "#4CAF50",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                fontSize: "1.1em",
                fontWeight: "bold",
              }}
              disabled={cartItemsFromRedux.length === 0}
              onClick={handleProceedToCheckout}
            >
              Proceed to Checkout
            </button>
          )}
        </div>
      </div>
      <Discover />
      <Footer />
    </div>
  );
};

export default CartSec;
