import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import Home from "./Pages/Home";
import ListingPage from "./Pages/Products/ListingPage";
import ProductListing from "./Pages/MenHeadrer/ProductListing";
import SignUp from "./Pages/Auth/SignUp";
import DetailsPage from "./Pages/Products/DetailsPage";
import CartSec from "./Pages/HeaderContent/CartSec";
import Profile from "./Pages/AccountSec/Profile";
import OrderHistory from "./Pages/AccountSec/OrderHistory";
import Checkout from "./Pages/Checkout/Checkout";
import ThreeD from "./Pages/ThreeD";
import { Address } from "./Pages/AccountSec/Address";
import AddAddress from "./Pages/AccountSec/AddAddress";
import OrderDetails from "./Pages/AccountSec/OrderDetails";
import ForgotPassward from "./Pages/Auth/ForgotPassward";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/listing" element={<ListingPage />} />
        <Route path="/productListing" element={<ProductListing />} />
        <Route path="/auth" element={<SignUp />} />
        <Route path="/detailsPage" element={<DetailsPage />} />
        <Route path="/cart" element={<CartSec />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/orderHistory" element={<OrderHistory />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/3d-product" element={<ThreeD/>} />
        <Route path="/addresses" element={<Address/>} />
        <Route path="/add-addresses" element={<AddAddress/>} />
        <Route path="/order-details" element={<OrderDetails/>} />
        <Route path="/forgot-password" element={<ForgotPassward/>} />
      </Routes>
    </Router>
  );
}

export default App;
