import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  items: Cookies.get("items") ? JSON.parse(Cookies.get("items")) : [],
  totalItems: Cookies.get("totalItem") ? Cookies.get("totalItem") : 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCart: (state, action) => {
      console.log("Redux product:", action.payload);
      console.log(
        "State items before add:",
        JSON.parse(JSON.stringify(state.items))
      );

      const existingItem = state.items.find(
        (item) =>
          item.id === action.payload.id &&
          item.size_id === action.payload.size_id
      );

      console.log("Existing item:", existingItem);

      if (!existingItem) {
        state.items = [...state.items, action.payload];
        state.totalItems += 1;
        console.log(
          "State items after add:",
          JSON.parse(JSON.stringify(state.items))
        );
      } else {
        console.log("Item already exists in the cart.");
      }
      Cookies.set("items", JSON.stringify(state.items));
      Cookies.set("totalItems", state.totalItems);
    },

    removeCart: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
      state.totalItems -= 1;
      Cookies.set("items", JSON.stringify(state.items));
      Cookies.set("totalItem", state.totalItems);
    },
    updateCartQuantity: (state, action) => {
      console.log("Payload : ", action.payload);
      const { id, quantity, price } = action.payload;
      const existingItem = state.items.find((item) => item.id === id);

      if (quantity > 0) {
        if (existingItem) {
          existingItem.quantity = quantity;
          existingItem.price = price;
        } else {
          state.items.push({ ...action.payload, quantity });
        }
      } else if (existingItem) {
        state.items = state.items.filter((item) => item.id !== id);
      }
      Cookies.set("items", JSON.stringify(state.items));
      Cookies.set("totalItem", state.items.length);
    },

    deleteAllCart: (state) => {
      console.log("Deleting all cart items...");
      state.items = [];
      state.totalItems = 0;
      Cookies.set("items", JSON.stringify(state.items));
      Cookies.set("totalItem", state.totalItems);

      console.log("All cart items cleared. Cookies updated.");
    },
  },
});

export const {
  addCart,
  removeCart,
  updateCartQuantity,
  clearCart,
  deleteAllCart,
} = cartSlice.actions;
export default cartSlice.reducer;
