import React, { useEffect, useState } from "react";
import { orderList } from "../../Services/Operations/ProductAPI";
import { useSelector } from "react-redux";
import Header from "../Components/Header";
import { useNavigate } from "react-router-dom";

const OrderHistory = () => {
  const [order, setOrder] = useState([]);
  const navigate=useNavigate();
  const { tokenDetails } = useSelector((state) => state.user);
  const token = tokenDetails;

  useEffect(() => {
    const userOrder = async () => {
      try {
        const response = await orderList(token);
        console.log("order data : ", response);
        if (response.success === true) {
          setOrder(response.data);
        }
      } catch (error) {
        console.error("Error getting orders:", error);
      }
    };
    userOrder();
  }, [token]);

  const handleCardClick = (id) => {
    navigate("/order-details", { state: { id } });
  };

  return (
    <div className="add-address-container body-container default is-ctl-home-page is-ctl-landingpage is-ctl-navigation is-act-home">
      <Header />
      <div style={styles.container}>
        <h2 style={styles.header}>Order History</h2>
        {order.length > 0 ? (
          <div style={styles.grid}>
            {order.map((orderItem, index) => {
              const date = new Date(orderItem.created_at);
              const formattedDate = date.toLocaleDateString();
              const formattedTime = date.toLocaleTimeString();

              return (
                <div key={index} style={styles.card}  onClick={() => handleCardClick(orderItem.id)}>
                  <div style={styles.row}>
                    <span style={styles.label}>Date:</span>
                    <span style={styles.value}>{formattedDate}</span>
                  </div>
                  <div style={styles.row}>
                    <span style={styles.label}>Time:</span>
                    <span style={styles.value}>{formattedTime}</span>
                  </div>
                  <div style={styles.row}>
                    <span style={styles.label}>Order Type:</span>
                    <span style={styles.value}>{orderItem.order_type}</span>
                  </div>
                  <div style={styles.row}>
                    <span style={styles.label}>Order Status:</span>
                    <span
                      style={{
                        ...styles.value,
                        color:
                          orderItem.order_status === "Pending"
                            ? "#ff4d4f"
                            : "#28a745",
                      }}
                    >
                      {orderItem.order_status}
                    </span>
                  </div>
                  <div style={styles.row}>
                    <span style={styles.label}>Total Amount:</span>
                    <span style={styles.value}>${orderItem.total_amount}</span>
                  </div>
                  <div style={styles.row}>
                    <span style={styles.label}>Payment Status:</span>
                    <span
                      style={{
                        ...styles.value,
                        color:
                          orderItem.payment_status === "Pending"
                            ? "#ff4d4f"
                            : "#28a745",
                      }}
                    >
                      {orderItem.payment_status}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p style={styles.noData}>No orders found.</p>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "90%",
    margin: "20px auto",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
    textAlign: "center",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "20px",
  },
  card: {
    padding: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    textAlign: "left",
  },
  cardHover: {
    transform: "scale(1.02)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    fontSize: "16px",
  },
  label: {
    fontWeight: "bold",
    color: "#555",
  },
  value: {
    color: "#333",
  },
  noData: {
    fontSize: "18px",
    color: "#888",
    marginTop: "20px",
  },
};

export default OrderHistory;
