import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_logout } from "../../Services/Operations/ProductAPI";
import { logout } from "../../Redux/user_information";
import "../../Styles/Header.css";
import { toast } from "react-toastify";

function AccountSec() {
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown visibility
  const navigate = useNavigate();
  const { tokenDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const token = tokenDetails;

  const handleLogOut = async () => {
    const result = await get_logout(token);
    console.log("log out data : ", result);
    if (result.success === true) {
      dispatch(logout());
      toast.success("Logout Successfully");
      navigate("/");
    } else {
      toast.error("User is not login!");
    }
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleAddressesClick = () => {
    if (!token) {
      navigate("/auth");
    } else {
      navigate("/addresses");
    }
  };

  return (
    <div style={{ marginRight: "20px" }}>
      <div
        className="account-menu"
        style={{ fontFamily: "sans-serif", marginBottom: "7px" }}
      >
        <div className="dropdown">
          <button
            className="btn button-icon btn-outline account-menu-btn header-actions-btn"
            type="button"
            id="accountWidget"
            aria-haspopup="true"
            aria-expanded={dropdownOpen ? "true" : "false"} // Update aria-expanded based on state
            aria-label="Your account"
            title="Your account"
            style={{
              padding: "0px",
              margin: "0px",
              width: "25px",
              height: "29px",
            }}
            onClick={toggleDropdown} // Toggle dropdown visibility
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.063"
              height="23.842"
              viewBox="0 0 20.063 23.842"
              style={{ width: "17px" }}
            >
              <g transform="translate(-12.125 -2.375)">
                <path
                  d="M39.375,9.238a4.863,4.863,0,1,0,4.863-4.863,4.863,4.863,0,0,0-4.863,4.863Z"
                  transform="translate(-22.082 -1)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <path
                  d="M13.125,87.781a9.031,9.031,0,1,1,18.063,0"
                  transform="translate(0 -62.565)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </button>

          {/* dropdown */}
          <div
            className={`dropdown-menu dropdown-menu-end account-menu-dropdown js-account-menu-dropdown ${
              dropdownOpen ? "show" : ""
            }`} // Add "show" class if dropdown is open
            aria-labelledby="accountWidget"
            style={{
              minWidth: "250px",
              padding: "15px",
              position: "absolute",
              left: "-632%",
            }}
          >
            <div className="offcanvas-header">
              <button className="btn btn-light btn-block offcanvas-close js-offcanvas-close sticky-top">
                <AiOutlineClose className="icon-close" />
                Close menu
              </button>
            </div>
            <div className="offcanvas-body">
              <div className="account-menu">
                <div className="account-menu-login">
                  {!tokenDetails ? (
                    <>
                      <a
                        title="Sign in"
                        onClick={() => (window.location.href = "/auth")}
                        className="btn button8 btn-outline account-menu-login-button"
                      >
                        Sign in
                      </a>
                      <div className="account-menu-register">or</div>
                      <a
                        title="register"
                        onClick={() => (window.location.href = "/auth")}
                        className="btn button8 btn-outline account-menu-login-button"
                      >
                        register
                      </a>
                    </>
                  ) : (
                    <a
                      href=" "
                      title="My Profile"
                      onClick={() => navigate("/profile")}
                      className="btn button8 btn-outline account-menu-login-button"
                    >
                      My Profile
                    </a>
                  )}
                </div>
                <div className="account-menu-links">
                  <div className="header-account-menu">
                    <div className="card account-menu-inner">
                      <div className="list-group list-group-flush account-aside-list-group">
                        <div className="list-group-box">
                          <a
                            href="en/account/loginb736.html"
                            title="Overview"
                            className="list-group-item list-group-item-action bottom-space account-aside-item"
                            style={{ borderWidth: 0 }}
                          >
                            Overview
                          </a>
                        </div>
                        <div className="list-group-box">
                          <a
                            href="en/account/loginead9.html"
                            title="Drafts"
                            className="list-group-item list-group-item-action account-aside-item"
                            style={{ borderWidth: 0 }}
                          >
                            Drafts
                          </a>
                          <div className="sidebar-team-list-box">
                            <a
                              href="en/account/login7090.html"
                              title="Team list"
                              className="list-group-item list-group-item-action account-aside-item"
                              style={{ borderWidth: 0 }}
                            >
                              Team list
                            </a>
                          </div>
                        </div>
                        <div className="list-group-box">
                          <a
                            href=""
                            title="Addresses"
                            className="list-group-item list-group-item-action account-aside-item"
                            style={{ borderWidth: 0 }}
                            onClick={handleAddressesClick}
                          >
                            Addresses
                          </a>
                          <a
                            href="en/account/loginfd3e.html"
                            title="Payment methods"
                            className="list-group-item list-group-item-action account-aside-item"
                            style={{ borderWidth: 0 }}
                          >
                            Payment methods
                          </a>
                          <a
                            href
                            onClick={() => navigate("/orderHistory")}
                            title="Orders"
                            className="list-group-item list-group-item-action bottom-space account-aside-item"
                            style={{ borderWidth: 0 }}
                          >
                            Orders
                          </a>
                        </div>
                        <div className="list-group-box">
                          <a
                            href="en/account/logineb81.html"
                            title="Teamshop"
                            className="list-group-item list-group-item-action account-aside-item"
                            style={{ borderWidth: 0 }}
                          >
                            Teamshop
                          </a>
                        </div>
                        <div className="list-group-box">
                          <a
                            title="Teamshop"
                            className="list-group-item list-group-item-action account-aside-item"
                            style={{ borderWidth: 0 }}
                            onClick={handleLogOut}
                          >
                            Logout
                          </a>
                        </div>
                        <div className="list-group-box"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountSec;
