import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Custom arrow components
const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      position: "absolute",
      top: "50%",
      right: "10px",
      transform: "translateY(-50%)",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      border: "none",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      cursor: "pointer",
      fontSize: "20px",
      textAlign: "center",
      zIndex: 1,
    }}
  >
    &#10095;
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      position: "absolute",
      top: "50%",
      left: "10px",
      transform: "translateY(-50%)",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      border: "none",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      cursor: "pointer",
      fontSize: "20px",
      textAlign: "center",
      zIndex: 1,
    }}
  >
    &#10094;
  </button>
);

function MiddleImage({ product }) {
  const images = [
    "https://www.spized.com/media/41/09/b0/1698030749/368f0c43-bd4c-9603-b9d2-92f913832e4d_781001.png",
    "https://www.spized.com/thumbnail/41/09/b0/1698030749/368f0c43-bd4c-9603-b9d2-92f913832e4d_781001_800x800.png",
    "https://www.spized.com/thumbnail/41/09/b0/1698030749/368f0c43-bd4c-9603-b9d2-92f913832e4d_781001_1280x1280.png",
    "https://www.spized.com/thumbnail/41/09/b0/1698030749/368f0c43-bd4c-9603-b9d2-92f913832e4d_781001_600x600.png",
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div style={{ maxWidth: "380px", }}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default MiddleImage;
