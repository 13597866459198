// import React from 'react'
// import Canvas from '../Pages/Components/canvas';
// import Customizer from '../Pages/Components/pages/Customizer';

// const ThreeD = () => {
//   return (
//     <div className="app transition-all ease-in">
//       <Canvas />
//       <Customizer />
//     </div>
//   )
// }

// export default ThreeD

import React, { Suspense, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload } from "@react-three/drei";
import "../Styles/3d.css";
import JerseyModel from "./ShirtModel.jsx";
import state from "./index.js";
import { useSnapshot } from "valtio";
import {
  FaAngleRight,
  FaAngleLeft,
  FaAngleDown,
  FaAngleUp,
} from "react-icons/fa";
import { FaPaintBrush } from "react-icons/fa";
import { IoIosColorPalette } from "react-icons/io";
import { AiOutlineNumber, AiOutlineQuestionCircle } from "react-icons/ai";
import { MdGradient, MdSupport } from "react-icons/md";
import { TbCircleDottedLetterT } from "react-icons/tb";
import { TbCircleDashedNumber1 } from "react-icons/tb";
import { FaRegFileImage } from "react-icons/fa";
import { TbCircleNumber1 } from "react-icons/tb";

function ThreeD() {
  const designs = [
    // { name: "full-tee-m", image: "/GLB/full-tee-m.png", model: "full-tee-m" },
    {
      name: "full-track-m",
      image: "/GLB/full-track-m.png",
      model: "full-track-m",
    },
    { name: "nosl-m", image: "/GLB/nosl-m.png", model: "nosl-m" },
    { name: "polo-m", image: "/GLB/polo-m.png", model: "polo-m" },
    { name: "shorts-m", image: "/GLB/shorts-m.png", model: "shorts-m" },
    { name: "v-neck-m", image: "/GLB/v-neck-m.png", model: "v-neck-m" },
    {
      name: "v-neck-full-m",
      image: "/GLB/vneck-full-m.png",
      model: "v-neck-full-m",
    },
  ];

  const patterns = [
    { name: "texture1", image: "/asset/texture1.jpeg" },
    { name: "Texture2", image: "/asset/Texture2.jpg" },
    { name: "Texture3", image: "/asset/Texture3.jpeg" },
  ];

  const [frontNumber, setFrontNumber] = useState("");
  const [backNumber, setBackNumber] = useState("");
  const [selectedDesign, setSelectedDesign] = useState(designs[0]);
  const [selectedPattern, setSelectedPattern] = useState({
    front: null,
    back: null,
  });
  const [openedColorPart, setOpenedColorPart] = useState(null);
  const [uploadedTexture, setUploadedTexture] = useState(null);
  const [frontText, setFrontText] = useState("");
  const [backText, setBackText] = useState("");
  const snap = useSnapshot(state);
  const [parts, setParts] = useState([]);
  const [color, setColor] = useState([]);
  const [logo, setLogo] = useState(null);
  const [partColors, setPartColors] = useState({
    front: "#000000", // default front text color
    back: "#000000", // default back text color
  });

  console.log("parts : ", parts);
  const [expandedSections, setExpandedSections] = useState({
    design: true,
    pattern: false,
    colors: false,
    text: false,
    number: false,
    logo: false,
  });
  const [activeTextField, setActiveTextField] = useState(null);

  const colorOptions = [
    "#fffc5b",
    "#f4cb39",
    "#f6a71a",
    "#f9886c",
    "#f9c1a8",
    "#f16f43",
    "#e8281e",
    "#e01c60",
    "#ae1444",
    "#932045",
    "#d95ca0",
    // "#d9a5d6",
    "#9264ad",
    "#643e72",
    "#601e45",
    "#7a99c8",
    // "#afccf1",
    "#7bb2e1",
    "#2064a6",
    "#134275",
    "#002d4d",
    "#113e74",
    "#5d8cb4",
    "#8acfd1",
    "#4abbb7",
    "#2a928a",
    "#266b6b",
    "#0d3f3f",
    "#8dd2ac",
    "#79c386",
    "#439f52",
    "#265934",
    "#bad14e",
    // "#d9f799",
    // "#faf799",
    "#c2bf4d",
    "#e6b64a",
    "#e98f2f",
    "#f98e7b",
    "#e6504a",
    "#f670b3",
    // "#fbbff2",
    "#d075d6",
    "#9f5cb0",
    // "#d0c0e8",
    "#536896",
    "#95cce6",
    "#5996c2",
    "#234769",
    "#1e6588",
    "#2a998c",
    "#226c54",
    "#5fd99a",
    // "#b8dbab",
    "#0500F7",
  ];

  useEffect(() => {
    setParts([]);
    setColor([]);
    setPartColors({
      front: "#000000", // Reset front text color to default
      back: "#000000", // Reset back text color to default
    });
  }, [selectedDesign]);

  const handleExpandToggle = (section) => {
    setExpandedSections({
      design: section === "design",
      pattern: section === "pattern",
      colors: section === "colors",
      text: section === "text",
      number: section === "number",
      logo: section === "logo",
    });
  };

  const handleColorPartToggle = (part) => {
    setOpenedColorPart((prevPart) => (prevPart === part ? null : part));
  };

  const handleColorChange = (part, color) => {
    setPartColors((prevColors) => ({
      ...prevColors,
      [part]: color,
    }));
  };

  const handleNumberColorChange = (part, color) => {
    setPartColors((prevColors) => ({
      ...prevColors,
      [part]: color,
    }));
  };

  const handleTextFieldToggle = (field) => {
    setActiveTextField((prevField) => (prevField === field ? null : field));
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogo(e.target.result); // Stores logo data URL for preview
      };
      reader.readAsDataURL(file);
    }
  };

  const designHandler = (design) => {
    setSelectedDesign(design);
    setSelectedPattern((prev) => ({
      front: prev.front,
      back: prev.back,
    }));
  };

  const handleFileUpload = (event, side) => {
    const file = event.target.files[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const uploadedTexture = e.target.result;

      if (side === "front") {
        setSelectedPattern((prevState) => ({
          ...prevState,
          front: { name: "uploadedTexture", image: uploadedTexture },
        }));
      } else if (side === "back") {
        setSelectedPattern((prevState) => ({
          ...prevState,
          back: { name: "uploadedTexture", image: uploadedTexture },
        }));
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="containerSection">
      <div className="sidebar">
        {/* Design Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            borderBottom: "1px solid #cbc6c6",
            marginTop: "10px",
          }}
        >
          <FaPaintBrush />
          <h3
            onClick={() => handleExpandToggle("design")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              marginTop: "10px",
            }}
            className="design-sidebar"
          >
            Design
            <span>
              {expandedSections.design ? <FaAngleLeft /> : <FaAngleRight />}
            </span>
          </h3>
        </div>
        {expandedSections.design && (
          <div className="Subdesign-section">
            <div className="design-section1">
              <div className="design-section" style={{ height: "fit-content" }}>
                {designs.map((design) => (
                  <div
                    key={design.name}
                    className={`design-thumbnail ${
                      selectedDesign.name === design.name ? "active" : ""
                    }`}
                    style={{
                      border:
                        selectedDesign.name === design.name
                          ? "2px solid blue"
                          : "1px solid gray",
                      padding: "5px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      maxHeight: "fit-content",
                    }}
                    onClick={() => designHandler(design)}
                  >
                    <img
                      src={design.image}
                      alt={design.name}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Pattern Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            borderBottom: "1px solid #cbc6c6",
            marginTop: "10px",
          }}
        >
          <MdGradient />
          <h3
            onClick={() => handleExpandToggle("pattern")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            Pattern
            <span>
              {expandedSections.pattern ? <FaAngleLeft /> : <FaAngleRight />}
            </span>
          </h3>
        </div>
        {expandedSections.pattern && (
          <div
            className="Subdesign-section"
            style={{ marginTop: "15px", flexDirection: "column" }}
          >
            <div className="design-section1">
              <p>Front Pattern : </p>
              <div
                className="pattern-section"
                style={{
                  display: "flex",
                  height: "fit-content",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft: "20px",
                }}
              >
                {patterns.map((pattern) => (
                  <div
                    key={`front-${pattern.name}`}
                    className={`pattern-thumbnail ${
                      selectedPattern.front?.name === pattern.name
                        ? "active"
                        : ""
                    }`}
                    style={{
                      border:
                        selectedPattern.front?.name === pattern.name
                          ? "3px solid #007BFF"
                          : "2px solid #ddd",
                      padding: "10px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transform:
                        selectedPattern.front?.name === pattern.name
                          ? "scale(1.1)"
                          : "scale(1)",
                      width: "100px",
                      height: "100px",
                    }}
                    onClick={() =>
                      setSelectedPattern((prev) => ({
                        ...prev,
                        front: pattern,
                      }))
                    }
                  >
                    <img
                      src={pattern.image}
                      alt={pattern.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>
              <div
                className="input-group"
                style={{ marginTop: "20px", zIndex: "1000000" }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, "front")} // Pass 'front' to handle the front upload
                  style={{ width: "84%", margin: "auto" }}
                />
              </div>
              <p>Back Pattern : </p>
              <div
                className="pattern-section"
                style={{
                  display: "flex",
                  height: "fit-content",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft: "20px",
                }}
              >
                {patterns.map((pattern) => (
                  <div
                    key={`back-${pattern.name}`}
                    className={`pattern-thumbnail ${
                      selectedPattern.back?.name === pattern.name
                        ? "active"
                        : ""
                    }`}
                    style={{
                      border:
                        selectedPattern.back?.name === pattern.name
                          ? "3px solid #007BFF"
                          : "2px solid #ddd",
                      padding: "10px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transform:
                        selectedPattern.back?.name === pattern.name
                          ? "scale(1.1)"
                          : "scale(1)",
                      width: "100px",
                      height: "100px",
                    }}
                    onClick={() =>
                      setSelectedPattern((prev) => ({
                        ...prev,
                        back: pattern,
                      }))
                    }
                  >
                    <img
                      src={pattern.image}
                      alt={pattern.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>

              <div
                className="input-group"
                style={{ marginTop: "20px", zIndex: "1000000" }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, "back")}
                  style={{ width: "84%", margin: "auto" }}
                />
              </div>
            </div>
          </div>
        )}

        {/* Colors Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            borderBottom: "1px solid #cbc6c6",
            marginTop: "10px",
          }}
        >
          <IoIosColorPalette style={{ fontSize: "22px" }} />
          <h3
            onClick={() => handleExpandToggle("colors")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            Colors{" "}
            <span>
              {expandedSections.colors ? <FaAngleLeft /> : <FaAngleRight />}
            </span>
          </h3>
        </div>
        {expandedSections.colors && (
          <div className="Subdesign-section">
            <div className="design-section1">
              <div className="color-selection" style={{ padding: "20px" }}>
                {parts.map((part, index) => (
                  <div key={part} className="color-section" style={{marginBottom:"10px"}}>
                    {/* Color Preview Circle */}
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          backgroundColor: partColors[part] || "white",
                          border: "2px solid #333",
                        }}
                      />
                      <p
                        onClick={() => handleColorPartToggle(part)}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          width: "100%",
                          marginBottom:"0px"
                        }}
                      >
                        {`Color ${index + 1}`}
                        <span>
                          {openedColorPart === part ? (
                            <FaAngleUp />
                          ) : (
                            <FaAngleDown />
                          )}
                        </span>
                      </p>
                    </div>
                    {openedColorPart === part && (
                      <div className="color-picker">
                        {colorOptions.map((color, colorIndex) => (
                          <div
                            key={colorIndex}
                            className={`color-box ${
                              partColors[part] === color ? "selected" : ""
                            }`}
                            onClick={() => handleColorChange(part, color)}
                            style={{ backgroundColor: "transparent" }}
                          >
                            <div
                              className="inner-color"
                              style={{ backgroundColor: color }}
                            ></div>
                            {partColors[part] === color && (
                              <div className="checkmark">✓</div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Text Section */}
        <div className="text-editor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #cbc6c6",
              marginTop: "10px",
            }}
          >
            <TbCircleDottedLetterT style={{ fontSize: "22px" }} />
            <h3
              onClick={() => handleExpandToggle("text")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Text{" "}
              <span>
                {expandedSections.text ? <FaAngleLeft /> : <FaAngleRight />}
              </span>
            </h3>
          </div>
          {expandedSections.text && (
            <div className="Subdesign-section">
              <div className="design-section1">
                <div className="text-section">
                  <div className="input-group">
                    <label htmlFor="front-text">Front Text:</label>
                    <input
                      id="front-text"
                      type="text"
                      placeholder="Enter Front Text"
                      value={frontText}
                      onChange={(e) => setFrontText(e.target.value)}
                    />
                  </div>
                  {frontText && (
                    <div>
                      <p
                        onClick={() => handleTextFieldToggle("front")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Front Text Colors
                        <span>
                          {activeTextField === "front" ? (
                            <FaAngleUp />
                          ) : (
                            <FaAngleDown />
                          )}
                        </span>
                      </p>
                      {activeTextField === "front" && (
                        <div className="color-picker">
                          {colorOptions.map((color, colorIndex) => (
                            <div
                              key={colorIndex}
                              className={`color-box ${
                                partColors["front"] === color ? "selected" : ""
                              }`}
                              onClick={() => handleColorChange("front", color)}
                              style={{
                                backgroundColor: color,
                                borderRadius: "50%",
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="input-group">
                    <label htmlFor="back-text">Back Text:</label>
                    <input
                      id="back-text"
                      type="text"
                      placeholder="Enter Back Text"
                      value={backText}
                      onChange={(e) => setBackText(e.target.value)}
                    />
                  </div>
                  {backText && (
                    <div>
                      <p
                        onClick={() => handleTextFieldToggle("back")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Back Text Colors
                        <span>
                          {activeTextField === "back" ? (
                            <FaAngleUp />
                          ) : (
                            <FaAngleDown />
                          )}
                        </span>
                      </p>
                      {activeTextField === "back" && (
                        <div className="color-picker">
                          {colorOptions.map((color, colorIndex) => (
                            <div
                              key={colorIndex}
                              className={`color-box ${
                                partColors["back"] === color ? "selected" : ""
                              }`}
                              onClick={() => handleColorChange("back", color)}
                              style={{
                                backgroundColor: color,
                                borderRadius: "50%",
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Number Section */}
        <div className="text-editor">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #cbc6c6",
            }}
          >
            <TbCircleDashedNumber1 style={{ fontSize: "22px" }} />
            <h3
              onClick={() => handleExpandToggle("number")}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Number
              <span>
                {expandedSections.number ? <FaAngleLeft /> : <FaAngleRight />}
              </span>
            </h3>
          </div>
          {expandedSections.number && (
            <div className="Subdesign-section">
              <div className="design-section1">
                <div className="text-section">
                  <div className="input-group">
                    <label htmlFor="front-text">Front Number:</label>
                    <input
                      id="front-text"
                      type="number"
                      placeholder="Enter Number"
                      value={frontNumber}
                      onChange={(e) => setFrontNumber(e.target.value)}
                    />
                  </div>
                  {frontNumber && (
                    <div>
                      <p
                        onClick={() => handleTextFieldToggle("front")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Front Number Colors
                        <span>
                          {activeTextField === "front" ? (
                            <FaAngleUp />
                          ) : (
                            <FaAngleDown />
                          )}
                        </span>
                      </p>
                      {activeTextField === "front" && (
                        <div className="color-picker">
                          {colorOptions.map((color, colorIndex) => (
                            <div
                              key={colorIndex}
                              className={`color-box ${
                                partColors["frontNumber"] === color
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                handleNumberColorChange("frontNumber", color)
                              }
                              style={{
                                backgroundColor: color,
                                borderRadius: "50%",
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="input-group">
                    <label htmlFor="front-text">Back Number:</label>
                    <input
                      id="front-text"
                      type="number"
                      placeholder="Enter Number"
                      value={backNumber}
                      onChange={(e) => setBackNumber(e.target.value)}
                    />
                  </div>
                  {backNumber && (
                    <div>
                      <p
                        onClick={() => handleTextFieldToggle("back")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Back Number Colors
                        <span>
                          {activeTextField === "back" ? (
                            <FaAngleUp />
                          ) : (
                            <FaAngleDown />
                          )}
                        </span>
                      </p>
                      {activeTextField === "back" && (
                        <div className="color-picker">
                          {colorOptions.map((color, colorIndex) => (
                            <div
                              key={colorIndex}
                              className={`color-box ${
                                partColors["backNumber"] === color
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                handleNumberColorChange("backNumber", color)
                              }
                              style={{
                                backgroundColor: color,
                                borderRadius: "50%",
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Logo Section */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            borderBottom: "1px solid #cbc6c6",
          }}
        >
          <FaRegFileImage style={{ fontSize: "22px" }} />
          <h3
            onClick={() => handleExpandToggle("logo")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            Logo{" "}
            <span>
              {expandedSections.logo ? <FaAngleLeft /> : <FaAngleRight />}
            </span>
          </h3>
        </div>
        {/* <div className="logo-uploader">
          <div className="Subdesign-section">
            {expandedSections.logo && (
              <input type="file" accept="image/*" onChange={handleLogoUpload} />
            )}
          </div>
        </div> */}
        <div className="logo-uploader">
          <div className="Subdesign-section">
            <div className="design-section1">
              {expandedSections.logo && (
                <div
                  className="input-group"
                  style={{
                    marginTop: "20px",
                    zIndex: "1000000",
                    height: "fit-content",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleLogoUpload}
                    style={{ width: "84%", margin: "auto" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="main-viewport">
        <Canvas style={{ backgroundColor: "white" }}>
          <ambientLight position={[15, 15, 15]} intensity={3} />
          {/* <pointLight position={[10, 10, 10]} intensity={7} color="white" /> */}
          <JerseyModel
            colors={partColors}
            setParts={setParts}
            setColor={setColor}
            logo={logo}
            modelName={selectedDesign.model}
            frontText={frontText}
            backText={backText}
            frontNumber={frontNumber}
            backNumber={backNumber}
            selectedPattern={selectedPattern}
          />
          {/* <OrbitControls /> */}
        </Canvas>
        {/* <Canvas
          frameloop="demand"
          shadows
          camera={{ position: [20, 3, 5], fov: 25 }}
          gl={{ preserveDrawingBuffer: true }}
        >
          <Suspense
            fallback={
              <CanvasLoader/>
            }
          >
            <OrbitControls
              enableZoom={false}
              maxPolarAngle={Math.PI / 2}
              minPolarAngle={Math.PI / 2}
            />
            <JerseyModel
                colors={partColors}
                setParts={setParts}
                setColor={setColor}
                logo={logo}
                modelName={selectedDesign.model}
                frontText={frontText}
                backText={backText}
                frontNumber={frontNumber}
                backNumber={backNumber}
                selectedPattern={selectedPattern}
              />
          </Suspense>

          <Preload all />
        </Canvas> */}
      </div>
    </div>
  );
}

export default ThreeD;


