import React, { useEffect, useState, useRef } from "react";
import * as THREE from "three";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import state from "./index.js";
import { useSnapshot } from "valtio";
import { OrbitControls, Text } from "@react-three/drei";
import { Rnd } from "react-rnd";
import { Html } from "@react-three/drei";
import Moveable from "react-moveable";
import { extend } from "@react-three/fiber";

function ShirtModel({
  colors,
  setParts,
  setColor,
  logo,
  modelName,
  frontText,
  backText,
  frontTextColor,
  backTextColor,
  frontNumber,
  backNumber,
  selectedPattern,
}) {
  const { scene } = useLoader(GLTFLoader, `/GLB/${modelName}.glb`);
  const snap = useSnapshot(state);
  const [size, setSize] = useState({ width: 0.2, height: 0.2 });
  const [position, setPosition] = useState([0, 0.1, 0.73]);
  const [backPosition, setBackPosition] = useState([0, -0.1, -0.6]);
  const [rotation, setRotation] = useState([0, 0, 0]);
  const [isEditing, setIsEditing] = useState(false);
  const [target, setTarget] = useState(null);
  const targetRef = useRef(null);

  console.log("selectedPattern : ",selectedPattern)

  const toggleEditing = () => setIsEditing(!isEditing);
  console.log("Scene : ", scene);
  console.log("colors : ", colors);

  const [logoTexture, setLogoTexture] = useState(null);

  useEffect(() => {
    if (logo) {
      const texture = new THREE.TextureLoader().load(logo);
      texture.colorSpace = THREE.SRGBColorSpace;
      setLogoTexture(texture);
    }
  }, [logo]);

  const createTextTexture = (text, font, size, color) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    ctx.font = `${size}px ${font}`;
    const textWidth = ctx.measureText(text).width;
    const textHeight = size;
    canvas.width = textWidth + 100;
    canvas.height = textHeight + 50;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = color || "white";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);
    return new THREE.CanvasTexture(canvas);
  };

  const frontTextTexture = frontText
    ? createTextTexture(frontText, "Arial", snap.frontTextSize, frontTextColor)
    : null;
  const backTextTexture = backText
    ? createTextTexture(backText, "Arial", snap.backTextSize, backTextColor)
    : null;

  const [displayFrontText, setDisplayFrontText] = useState(null);
  const [displayBackText, setDisplayBackText] = useState(null);

  const targetRefFront = useRef(null);
  const targetRefBack = useRef(null);
  const targetRefFrontNumber = useRef(null);
  const targetRefBackNumber = useRef(null);

  useEffect(() => {
    if (frontText) {
      setDisplayFrontText(frontText);
    }
    if (backText) {
      setDisplayBackText(backText);
    }
  }, [frontText, backText]);

  useEffect(() => {
    const parts = [];
    const colors = [];
    let partCounter = 1;
    scene.traverse((child) => {
      if (child.isMesh) {
        parts.push(`color${parts.length + 1}`);
        const colorKey = `color${partCounter}`;
        child.material = child.material.clone();
        if (child.material) {
          if (colors[colorKey]) {
            // Explicitly set the color, removing any default color blending
            // child.material.map = null; 
            child.material.color.set(new THREE.Color(colors[colorKey]));
            // child.material.color.set(colors[colorKey]);
          } else if (child.material.color) {
            const hexColor = `#${child.material.color
              .getHexString()
              .toUpperCase()}`;
            colors.push(hexColor);
            // child.material.map = null; 
          }
        }
      }
    });

    setParts(parts);
    setColor(colors);
  }, [scene]);

  

  // useEffect(() => {
  //     const parts = [];
  //     const colors = [];

  //     scene.traverse((child) => {
  //         if (child.isMesh) {
  //             parts.push(`color${parts.length + 1}`);
  //             if (child.material) {
  //                 if (child.material.map) {
  //                     colors.push(child.material.map.image?.src || "Texture applied");
  //                 } else if (child.material.color) {
  //                     const hexColor = `#${child.material.color.getHexString().toUpperCase()};`
  //                     colors.push(hexColor);
  //                 }
  //             }
  //         }
  //     });

  //     setParts(parts);
  //     setColor(colors);
  // }, [scene]);

  // useEffect(() => {
  //   let frontMesh = null;
  //   let backMesh = null;
  
  //   scene.traverse((child) => {
  //     if (child.isMesh) {
  //       // Check for specific names or userData to find front and back meshes
  //       if (child.name.includes('front')) {
  //         frontMesh = child;
  //       } else if (child.name.includes('back')) {
  //         backMesh = child;
  //       }
  //     }
  //   });
  
  //   // Apply front pattern
  //   if (frontMesh && selectedPattern.front) {
  //     const frontTexture = new THREE.TextureLoader().load(selectedPattern.front.image);
  //     frontTexture.colorSpace = THREE.SRGBColorSpace;
  //     frontMesh.material.map = frontTexture;
  //     frontMesh.material.needsUpdate = true;
  //   }
  
  //   // Apply back pattern
  //   if (backMesh && selectedPattern.back) {
  //     const backTexture = new THREE.TextureLoader().load(selectedPattern.back.image);
  //     backTexture.colorSpace = THREE.SRGBColorSpace;
  //     backMesh.material.map = backTexture;
  //     backMesh.material.needsUpdate = true;
  //   }
  // }, [scene, selectedPattern]);
  
  useEffect(() => {
    const meshes = [];
    scene.traverse((child) => {
      if (child.isMesh) {
        meshes.push(child); // Collect all meshes
      }
    });
  
    const frontMesh = meshes[4];  
    const backMesh = meshes[0];  
  
    if (frontMesh && selectedPattern.front) {
      const frontTexture = new THREE.TextureLoader().load(selectedPattern.front.image);
      frontTexture.colorSpace = THREE.SRGBColorSpace;
      frontMesh.material.map = frontTexture;
      frontMesh.material.needsUpdate = true;
    }

    if (backMesh && selectedPattern.back) {
      const backTexture = new THREE.TextureLoader().load(selectedPattern.back.image);
      backTexture.colorSpace = THREE.SRGBColorSpace;
      backMesh.material.map = backTexture;
      backMesh.material.needsUpdate = true;
    }
  }, [scene, selectedPattern]);

  
  useEffect(() => {
    let partCounter = 1;
    scene.traverse((child) => {
      if (child.isMesh) {
        const colorKey = `color${partCounter}`;
        child.material = child.material.clone();
        child.material.color.set(colors[colorKey]);
        partCounter++;
      }
    });
  }, [scene, colors]);

  let frontMesh = null;
  let backMesh = null;
  scene.traverse((child) => {
    if (child.isMesh && !frontMesh) {
      frontMesh = child;
    } else if (child.isMesh && !backMesh) {
      backMesh = child;
    }
  });
  const handleRotationChange = (newRotation) => {
    setRotation(newRotation); // Rotate jersey model independently
  };

  return (
    <>
      {/* Separate OrbitControls for rotating the model */}
      <OrbitControls
        enableZoom={true}
        enablePan={true}
        onChange={(e) =>
          handleRotationChange([
            e.target.getAzimuthalAngle(),
            e.target.getPolarAngle(),
            0,
          ])
        }
      />
      <Html position={position} transform>
        {frontText && (
          <div
            ref={targetRefFront}
            style={{
              fontSize: "20px",
              color: colors.front || "white",
              textAlign: "center",
              border: isEditing ? "2px dashed white" : "none",
              padding: "5px",
              cursor: isEditing ? "move" : "pointer",
            }}
            onClick={toggleEditing}
          >
            {frontText}
          </div>
        )}

        {isEditing && frontText && (
          <Moveable
            target={targetRefFront.current}
            draggable={true}
            resizable={true}
            onDrag={({ target, left, top }) => {
              setPosition([left / 90, -top / 90, position[2]]);
              target.style.left = `${left}px`;
              target.style.top = `${top}px`;
            }}
            onResize={({ target, width, height }) => {
              target.style.width = `${width}px`;
              target.style.height = `${height}px`;
            }}
          />
        )}

        {frontNumber && (
          <div
            ref={targetRefFrontNumber}
            style={{
              fontSize: "24px",
              color: colors.frontNumber || "white",
              textAlign: "center",
              border: isEditing ? "2px dashed white" : "none",
              padding: "5px",
              cursor: isEditing ? "move" : "pointer",
            }}
            onClick={toggleEditing}
          >
            {frontNumber}
          </div>
        )}

        {isEditing && frontNumber && (
          <Moveable
            target={targetRefFrontNumber.current}
            draggable={true}
            resizable={true}
            onDrag={({ target, left, top }) => {
              setPosition([left / 100, -top / 100, position[2]]);
              target.style.left = `${left}px`;
              target.style.top = `${top}px`;
            }}
            onResize={({ target, width, height }) => {
              target.style.width = `${width}px`;
              target.style.height = `${height}px`;
            }}
          />
        )}
      </Html>

      <Html position={backPosition} transform>
        {backText && (
          <div
            ref={targetRefBack}
            style={{
              fontSize: "20px",
              color: colors.back || "white",
              textAlign: "center",
              border: isEditing ? "2px dashed white" : "none",
              padding: "5px",
              cursor: isEditing ? "move" : "pointer",
            }}
            onClick={toggleEditing}
          >
            {backText}
          </div>
        )}

        {isEditing && backText && (
          <Moveable
            target={targetRefBack.current}
            draggable={true}
            resizable={true}
            onDrag={({ target, left, top }) => {
              setBackPosition([left / 100, -top / 100, position[2]]);
              target.style.left = `${left}px`;
              target.style.top = `${top}px`;
            }}
            onResize={({ target, width, height }) => {
              target.style.width = `${width}px`;
              target.style.height = `${height}px`;
            }}
          />
        )}

        {backNumber && (
          <div
            ref={targetRefBackNumber}
            style={{
              fontSize: "24px",
              color: colors.backNumber || "white",
              textAlign: "center",
              border: isEditing ? "2px dashed white" : "none",
              padding: "5px",
              cursor: isEditing ? "move" : "pointer",
            }}
            onClick={toggleEditing}
          >
            {backNumber}
          </div>
        )}

        {isEditing && backNumber && (
          <Moveable
            target={targetRefBackNumber.current}
            draggable={true}
            resizable={true}
            onDrag={({ target, left, top }) => {
              setBackPosition([left / 100, -top / 100, position[2]]);
              target.style.left = `${left}px`;
              target.style.top = `${top}px`;
            }}
            onResize={({ target, width, height }) => {
              target.style.width = `${width}px`;
              target.style.height = `${height}px`;
            }}
          />
        )}
      </Html>

      <group>
        <primitive object={scene} scale={5} position={[0, -0.5, 0]} />

        {/* {backText && backMesh && backTextTexture && (
          <Text
            position={[0, -0.04, -0.6]}
            rotation={[0, Math.PI, 0]}
            fontSize={0.19}
            color={colors.back || "white"}
            anchorX="center"
            anchorY="middle"
            maxWidth={2}
            overflow="hidden"
          >
            {displayBackText}
          </Text>
        )} */}

        {/* {frontNumber && frontMesh && frontTextTexture && (
          <Text
            position={[0.4, 0.8, 0.65]}
            rotation={[0, 0, 0]}
            fontSize={0.24}
            color={colors.frontNumber || "white"}
            anchorX="center"
            anchorY="middle"
            maxWidth={0.15}
            overflow="hidden"
          >
            {frontNumber}
          </Text>
        )} */}

        {/* {backNumber && backMesh && backTextTexture && (
          <Text
            position={[-0.4, 0.8, -0.8]}
            rotation={[0, Math.PI, 0]}
            fontSize={0.24}
            color={colors.backNumber || "white"}
            anchorX="center"
            anchorY="middle"
            maxWidth={2}
            overflow="hidden"
          >
            {backNumber}
          </Text>
        )} */}

        {/* Logo Display */}
        {logoTexture && (
          <mesh position={[-0.4, 1, 0.6]} rotation={[0, 0, 0]}>
            <planeGeometry args={[0.5, 0.5]} />
            <meshBasicMaterial map={logoTexture} transparent={true} />
          </mesh>
        )}
      </group>
    </>
  );
}

export default ShirtModel;
